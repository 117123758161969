import { useMemo } from 'react'
import { useDataQuery } from './useDataQuery'

export const useCustomerHasTargets = () => {
  // if this gets slow, Alex promised me some sort of exists() functionality. https://linear.app/delicious-data/issue/TEC-2105/[parent]-comparison-20#comment-f8649ca2
  const { data } = useDataQuery({
    from: 'GoalInterval',
    select: ['count(id)']
  }, true, null, { staleTime: Infinity })

  return useMemo(() => {
    if (data == null) return true
    return data > 0
  }, [data])
}
