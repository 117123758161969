import { usePrevious } from 'ahooks'
import classNames from 'classnames'
import { Button } from 'components/Button'
import { FilterFooter } from 'components/FilterFooter'
import Heading from 'components/Heading'
import labelMessages from 'components/labelMessages'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { Calendar, Checkbox, Popover, Text, Tooltip } from 'components/Primitives'
import { ProIcon } from 'components/ProIcon'
import { RenderCount } from 'components/RenderCount'
import { TextSelect } from 'components/TextSelect'
import { useIsMobile } from 'hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import rangePickerMessages from 'components/RangePicker/messages'
import messages from './messages'
import { CUSTOM_RANGE, FLOATING_RANGE } from 'constants/index'
import { FloatingPeriodPicker, formatFloatingPeriod } from './Partials/FloatingPeriodPicker'
import globalMessages from 'components/globalMessages'
import { RangePreview } from 'components/RangePreview'
import { calculateComparisonRange, calculateFloatingRange, formatLocalizedRange, getDateRangeValue, getWeekdayInPreviousYear, rangeToFloating } from 'utils/datetime'
import { addDays, differenceInCalendarDays, isAfter, min as minDate, startOfDay } from 'date-fns'
import { getRangePreset } from 'utils'
import { useComparingPeriodStore } from 'hooks/store/useComparingPeriodStore' // For some reason, importing from hooks/store/index.js breaks things
import { isArray, isEqual, omit } from 'lodash'
import { useCustomerHasTargets } from 'hooks/queries/useCustomerHasTargets'

export const OPTION_VALUES = {
  YOY: 'yearOverYear',
  POP: 'precedingPeriod',
  WOW: 'weekOverWeek',
  CDR: 'customDate',
  TGT: 'target',
  UNSET: 'unset'
}

export const getComparingOptionLabel = (intl, state, dateFilter, targetRange) => {
  let option = typeof state === 'string' ? state : state[FIELD.comparisonOption]
  if (!isValidCombination(option, dateFilter.option)) {
    switch (dateFilter.option) {
      default:
      case 'thisWeek':
      case 'weekToDate':
      case 'lastWeek':
      case 'thisMonth':
      case 'monthToDate':
      case 'lastMonth':
      case 'thisQuarter':
      case 'quarterToDate':
      case 'lastQuarter':
      case CUSTOM_RANGE:
      case FLOATING_RANGE:
        option = OPTION_VALUES.POP
        break
      case 'thisYear':
      case 'yearToDate':
      case 'lastYear':
        option = OPTION_VALUES.YOY
        break
    }
  }

  switch (option) {
    default:
    case OPTION_VALUES.YOY:
      switch (dateFilter.option) {
        case 'today':
        case 'yesterday':
        case 'tomorrow':
        case 'thisWeek':
        case 'weekToDate':
        case 'lastWeek':
        case 'thisMonth':
        case 'monthToDate':
        case 'lastMonth':
        case 'thisQuarter':
        case 'quarterToDate':
        case 'lastQuarter':
        case 'thisYear':
        case 'yearToDate':
          if (targetRange?.length > 0 &&
            targetRange[0] &&
            new Date(targetRange[0]).getFullYear() === new Date().getFullYear() - 1) {
            return intl.formatMessage(messages.yearBeforeLast)
          }
          return intl.formatMessage(rangePickerMessages.lastYear)
        case 'lastYear':
          return intl.formatMessage(messages.yearBeforeLast)
        default:
        case CUSTOM_RANGE:
        case FLOATING_RANGE:
          return intl.formatMessage(rangePickerMessages.lastYear)
      }
    case OPTION_VALUES.POP:
      switch (dateFilter.option) {
        case 'today':
          return intl.formatMessage(globalMessages.yesterday)
        case 'yesterday':
          return intl.formatMessage(messages.previousNDays, { days: 1 })
        case 'tomorrow':
          return intl.formatMessage(globalMessages.today)
        case 'thisWeek':
          return intl.formatMessage(rangePickerMessages.lastWeek)
        case 'weekToDate':
          return intl.formatMessage(rangePickerMessages.lastWeek)
        case 'lastWeek':
          return intl.formatMessage(messages.previousWeek)
        case 'thisMonth':
          return intl.formatMessage(rangePickerMessages.lastMonth)
        case 'monthToDate':
          return intl.formatMessage(rangePickerMessages.lastMonth)
        case 'lastMonth':
          return intl.formatMessage(messages.previousMonth)
        case 'thisQuarter':
          return intl.formatMessage(rangePickerMessages.lastQuarter)
        case 'quarterToDate':
          return intl.formatMessage(rangePickerMessages.lastQuarter)
        case 'lastQuarter':
          return intl.formatMessage(messages.previousQuarter)
        default:
        case CUSTOM_RANGE:
        case FLOATING_RANGE:
          return intl.formatMessage(messages.previousNDays, { days: getRangeLength(targetRange) })
      }
    case OPTION_VALUES.WOW:
      return intl.formatMessage(rangePickerMessages.lastWeek)
    case OPTION_VALUES.CDR:
      if (typeof state === 'string') return intl.formatMessage(messages.customStartDate)
      const compRange = calculateComparisonRange(dateFilter, state)
      if (state[FIELD.isCustomFixed]) return formatLocalizedRange(compRange, intl)
      const m = formatFloatingPeriod(rangeToFloating(compRange || []), intl)
      return (intl.locale === 'en-US') ? m.toLowerCase() : m
    case OPTION_VALUES.TGT:
      return intl.formatMessage(labelMessages.target)
    case OPTION_VALUES.UNSET:
      return intl.formatMessage(messages.noComparison)
  }
}

const getSettingLabel = (preset) => {
  switch (preset) {
    default:
    case 'today':
    case 'yesterday':
    case 'tomorrow':
    case 'thisYear':
    case 'yearToDate':
    case 'lastYear':
    case 'nextYear':
    case CUSTOM_RANGE:
    case FLOATING_RANGE:
      return labelMessages.date
    case 'thisWeek':
    case 'weekToDate':
    case 'lastWeek':
    case 'nextWeek':
      return messages.isoWeek
    case 'thisMonth':
    case 'monthToDate':
    case 'lastMonth':
    case 'nextMonth':
      return messages.month
    case 'thisQuarter':
    case 'quarterToDate':
    case 'lastQuarter':
    case 'nextQuarter':
      return messages.quarter
  }
}

export const FIELD = {
  comparisonOption: 'option',
  prevYSetting: 'setting',
  start: 'start',
  floating: 'floating',
  isCustomFixed: 'fixed'
}

export const DefaultState = {
  [FIELD.comparisonOption]: OPTION_VALUES.UNSET,
  [FIELD.prevYSetting]: 'proximity',
  [FIELD.isCustomFixed]: true,
  [FIELD.floating]: { from: {}, to: {} },
  [FIELD.start]: null
}

export const getRangeLength = (range) => {
  if (!range || !range.length) return 0
  return differenceInCalendarDays(range[1], range[0]) + 1
}

export const isValidCombination = (comparison, dateFilter) => !InvalidPairs.some(([a, b]) => a === comparison && b === dateFilter)

export const ComparisonPeriodPicker = ({ value, onChange, disabled, type, comparisonPeriodPickerKey }) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const isMobile = useIsMobile()
  const targetsEnabled = useCustomerHasTargets()

  const [state, setState] = useState({
    ...DefaultState,
    ...omit(value, 'range')
  })
  const [range, setRange] = useState([])

  const callOnChange = useCallback((newState) => {
    if (!isEqual(newState, value)) {
      onChange(newState)
    }
  }, [value, onChange])

  const currentDate = useComparingPeriodStore(state => state.currentDate[comparisonPeriodPickerKey])

  // we can't import DASHBOARD_DEFAULT_FILTERS.dateRange here due to circular references
  const dateFilter = useMemo(() =>
    !currentDate?.option ||
   (currentDate.option === CUSTOM_RANGE && currentDate.value.length === 0) ||
   (currentDate.option === FLOATING_RANGE && !calculateFloatingRange(currentDate.value))
      ? {
          value: getRangePreset('yesterday', true),
          option: 'yesterday'
        }
      : currentDate, [currentDate])

  const targetRange = useMemo(() => getDateRangeValue(dateFilter), [dateFilter])

  const rangeLength = getRangeLength(targetRange)
  const maxStart = addDays(targetRange[0], -rangeLength)

  const handleChange = (key, val) => {
    switch (key) {
      case FIELD.comparisonOption:
        setState(s => ({ ...s, [FIELD.comparisonOption]: val || DefaultState[FIELD.comparisonOption] }))
        break
      case FIELD.prevYSetting:
        setState(s => ({ ...s, [FIELD.prevYSetting]: val || DefaultState[FIELD.prevYSetting] }))
        break
      case FIELD.start:
        setState(s => ({
          ...s,
          [FIELD.start]: minDate([
            maxStart,
            val || getWeekdayInPreviousYear(getRangePreset('today', true)[0], true)
          ])
        }))
        break
      case FIELD.floating:
        setState(s => ({ ...s, [FIELD.floating]: val || DefaultState[FIELD.floating] }))
        break
      case FIELD.isCustomFixed:
        setState(s => ({ ...s, [FIELD.isCustomFixed]: val !== undefined ? val : DefaultState[FIELD.isCustomFixed] }))
        break
      case null:
        setState(s => ({ ...s, ...(val || DefaultState) }))
    }
  }

  useEffect(() => {
    const newRange = calculateComparisonRange(dateFilter, state)
    if (!isEqual(newRange?.map(startOfDay), range.map(startOfDay))) {
      setRange(newRange)
    }
  }, [dateFilter, state, range])

  // When the target range changes, we should check if the comparison start is valid
  useEffect(() => {
    if (!state[FIELD.start] || isAfter(startOfDay(state[FIELD.start]), startOfDay(maxStart))) handleChange('start', maxStart)
  }, [maxStart, state[FIELD.start], state[FIELD.comparisonOption]])

  useEffect(() => {
    if (state[FIELD.comparisonOption] === OPTION_VALUES.CDR && !state[FIELD.isCustomFixed]) return
    const newFloating = rangeToFloating(range, targetRange[0])
    if (!isEqual(state[FIELD.floating]?.from, newFloating?.from)) handleChange(FIELD.floating, newFloating)
  }, [range, targetRange])

  useEffect(() => {
    if (state[FIELD.comparisonOption] === OPTION_VALUES.CDR && state[FIELD.isCustomFixed]) return
    const newStart = (calculateFloatingRange(state[FIELD.floating], false, true, targetRange[0]) || [])[0] || new Date()
    if (!isEqual(state[FIELD.start], newStart)) handleChange(FIELD.start, newStart)
  }, [state[FIELD.floating]])

  useEffect(() => {
    if (!open) {
      const isStateValid = isValidCombination(state[FIELD.comparisonOption], dateFilter.option)
      const isValueValid = value && isValidCombination(value[FIELD.comparisonOption], dateFilter.option)

      if (isValueValid) {
        if (state[FIELD.comparisonOption] !== value[FIELD.comparisonOption] && !applying) handleChange(FIELD.comparisonOption, value[FIELD.comparisonOption])
      } else if (!isStateValid) {
        switch (dateFilter.option) {
          default:
          case 'thisWeek':
          case 'weekToDate':
          case 'lastWeek':
          case 'thisMonth':
          case 'monthToDate':
          case 'lastMonth':
          case 'thisQuarter':
          case 'quarterToDate':
          case 'lastQuarter':
          case CUSTOM_RANGE:
          case FLOATING_RANGE:
            if (state[FIELD.comparisonOption] !== OPTION_VALUES.POP) handleChange(FIELD.comparisonOption, OPTION_VALUES.POP)
            break
          case 'thisYear':
          case 'yearToDate':
          case 'lastYear':
            if (state[FIELD.comparisonOption] !== OPTION_VALUES.YOY) handleChange(FIELD.comparisonOption, OPTION_VALUES.YOY)
            break
        }
      }
    }
  }, [open, dateFilter, state])

  // controllable
  useEffect(() => {
    if (!isEqual(value, state)) handleChange(null, value)
    setApplying(false)
  }, [value])

  // Reset to last applied value when closing the popover
  const previousOpen = usePrevious(open)
  const [applying, setApplying] = useState(false)
  const onCloseHelper = useCallback(() => {
    if (!applying) handleChange(null, value)
  }, [value, applying])
  useEffect(() => {
    if (previousOpen && !open) onCloseHelper()
  }, [open, isMobile, previousOpen, onCloseHelper])

  const comparisonOptions = useMemo(() => Object.keys(OPTION_VALUES)
    .filter(opt => isValidCombination(OPTION_VALUES[opt], dateFilter.option)).map((k) => ({
      value: OPTION_VALUES[k],
      isDisabled: (OPTION_VALUES[k] === OPTION_VALUES.TGT && !targetsEnabled),
      label: getComparingOptionLabel(intl, OPTION_VALUES[k], dateFilter, targetRange)
    })), [intl, dateFilter, targetRange, targetsEnabled])

  const reset = () => {
    callOnChange(DefaultState)
    setOpen(false)
  }

  const onApplyButtonClick = () => {
    setApplying(true)
    callOnChange(state)
    setOpen(false)
  }

  const onCancelButtonClick = () => {
    setOpen(false)
  }

  const hasDefaultValue = isEqual(omit(state, 'start'), omit(DefaultState, 'start'))

  const renderContent = () => {
    return (
      <>
        <RenderCount />
        <div className='my-4 flex justify-between'>
          <Heading type='h5'>
            {intl.formatMessage(labelMessages.comparisonPeriod)}
            <ProIcon />
          </Heading>
          <Button size='s' name='btn-reset' type='tertiary' className='px-0 bg-transparent' disabled={hasDefaultValue} onClick={reset}>{intl.formatMessage(globalMessages.reset)}</Button>
        </div>
        <TextSelect
          id='comparisonOption'
          className='w-full'
          value={state[FIELD.comparisonOption]}
          onChange={(v) => handleChange(FIELD.comparisonOption, v)}
          creatable={false}
          allowClear={false}
          required
          options={comparisonOptions}
        />
        {state[FIELD.comparisonOption] === OPTION_VALUES.YOY && (
          <>
            <Text className='mt-4' title={intl.formatMessage(messages.matchBy)} color='black'>{intl.formatMessage(messages.matchBy)}</Text>
            <TextSelect
              id='comparisonSetting'
              className='w-full'
              value={state[FIELD.prevYSetting]}
              onChange={(v) => handleChange(FIELD.prevYSetting, v)}
              creatable={false}
              allowClear={false}
              required
              options={[
                { value: 'proximity', label: intl.formatMessage(messages.matchingWeekdays) },
                { value: 'date', label: intl.formatMessage(getSettingLabel(dateFilter.option)) }
              ]}
            />
          </>
        )}
        {state[FIELD.comparisonOption] === OPTION_VALUES.CDR && state[FIELD.isCustomFixed] && <Calendar className='mt-4' onChange={v => handleChange(FIELD.start, v)} value={state[FIELD.start]} maxValue={maxStart} />}
        {state[FIELD.comparisonOption] === OPTION_VALUES.CDR && !state[FIELD.isCustomFixed] && <FloatingPeriodPicker className='mt-4' onChange={v => handleChange(FIELD.floating, v)} value={state[FIELD.floating]} reference={targetRange[0]} fixedRangeLength={rangeLength} maxDate={maxStart} invalidRangeMessage={<FormattedMessage {...globalMessages.invalidComparingPeriod} />} />}
        {!(state[FIELD.comparisonOption] === OPTION_VALUES.CDR && !state[FIELD.isCustomFixed]) && state[FIELD.comparisonOption] !== OPTION_VALUES.UNSET && state[FIELD.comparisonOption] !== OPTION_VALUES.TGT && <RangePreview className='mt-4' range={range} />}
        {state[FIELD.comparisonOption] === OPTION_VALUES.CDR && (
          <Tooltip title={intl.formatMessage(messages.rollingStartDateTooltip)}>
            <Checkbox checked={!state[FIELD.isCustomFixed]} onCheckedChange={v => handleChange(FIELD.isCustomFixed, !v)} label={intl.formatMessage(messages.rollingStartDate)} />
          </Tooltip>
        )}
      </>
    )
  }

  const renderTrigger = () => {
    return (
      <Button
        icon='Compare'
        type='secondary'
        data-role='comparison-period-picker'
        pure
        whiteHoverBg
        className={classNames('mb-2 mr-2', {
          'hover:shadow-grid': !disabled,
          'bg-gray-lighter': type === 'templates' && !disabled,
          'h-7': type === 'templates',
          'bg-white': type !== 'templates' && !disabled,
          'h-9': type !== 'templates',
          'text-gray-light': hasDefaultValue,
          'bg-disabled cursor-default text-gray-light': disabled
        })}
        onClick={() => setOpen(!open)}
        disabled={disabled}
      >
        {state[FIELD.comparisonOption] === OPTION_VALUES.UNSET
          ? intl.formatMessage(messages.noComparison)
          : `vs. ${getComparingOptionLabel(intl, state, dateFilter, targetRange)}`}
      </Button>
    )
  }

  const buttonDisabled = false // if rolling/custom or otherwise invalid

  if (isMobile) {
    return (
      <>
        {renderTrigger()}
        {open && (
          <MobileFriendlyModal
            visible={open}
            title={intl.formatMessage(labelMessages.comparisonPeriod)}
            onCancel={onCancelButtonClick}
            onPrimary={onApplyButtonClick}
            prima
            footer={{
              primaryText: intl.formatMessage(globalMessages.ok),
              primaryDisabled: buttonDisabled
            }}
          >
            {renderContent()}
          </MobileFriendlyModal>
        )}
      </>
    )
  }
  return (
    <>
      <RenderCount />
      <Popover
        open={open}
        onOpenChange={setOpen}
        trigger={renderTrigger()}
        inModal={type === 'templates'}
      >
        <div className='flex flex-col p-4 min-w-72' style={{ maxHeight: `calc(100vh - ${type === 'templates' ? '320' : '120'}px)` }}>
          {renderContent()}
          <FilterFooter onCancel={onCancelButtonClick} onSubmit={onApplyButtonClick} submitDisabled={buttonDisabled} />
        </div>
      </Popover>
    </>
  )
}

// According to spreadsheet from TEC-2105
const InvalidPairs = [
  [OPTION_VALUES.WOW, 'thisWeek'],
  [OPTION_VALUES.WOW, 'weekToDate'],
  [OPTION_VALUES.WOW, 'lastWeek'],
  [OPTION_VALUES.WOW, 'thisMonth'],
  [OPTION_VALUES.WOW, 'monthToDate'],
  [OPTION_VALUES.WOW, 'lastMonth'],
  [OPTION_VALUES.WOW, 'thisQuarter'],
  [OPTION_VALUES.WOW, 'quarterToDate'],
  [OPTION_VALUES.WOW, 'lastQuarter'],
  [OPTION_VALUES.WOW, 'thisYear'],
  [OPTION_VALUES.WOW, 'yearToDate'],
  [OPTION_VALUES.WOW, 'lastYear'],
  [OPTION_VALUES.WOW, CUSTOM_RANGE],
  [OPTION_VALUES.WOW, FLOATING_RANGE],
  [OPTION_VALUES.POP, 'thisYear'],
  [OPTION_VALUES.POP, 'yearToDate'],
  [OPTION_VALUES.POP, 'lastYear']
]

export const comparingPeriodMigration = (comparingPeriod) => {
  const optionMap = {
    previousYear: OPTION_VALUES.YOY,
    previousPeriod: OPTION_VALUES.POP,
    custom: OPTION_VALUES.CDR,
    floating: OPTION_VALUES.CDR
  }
  return {
    [FIELD.comparisonOption]: optionMap[comparingPeriod.option] || OPTION_VALUES.YOY,
    [FIELD.prevYSetting]: comparingPeriod.setting || 'proximity',
    [FIELD.start]: (isArray(comparingPeriod.value) && comparingPeriod.value.length ? comparingPeriod.value[0] : null) || [],
    [FIELD.isCustomFixed]: comparingPeriod.option !== FLOATING_RANGE,
    [FIELD.floating]: comparingPeriod.option === FLOATING_RANGE ? comparingPeriod.value : null
  }
}
