import s from './OperatorPanel.module.scss'
import { useMemo, useState } from 'react'
import { Button } from 'components/Button'
import { useSettingsStore } from 'hooks/store'
import { useCustomers, useIsDesktop, useRole, useSelectedCustomer } from 'hooks'
import { OPERATOR } from 'constants/index'
import { Badge } from 'components/Primitives'
import useIsMacOS from 'hooks/useIsMacOS'
import { useKeyPress } from 'ahooks'
import { OperatorDialog } from 'components/OperatorDialog'
import { useIntl } from 'react-intl'
import labelMessages from 'components/labelMessages'

export const OperatorPanel = () => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const { data } = useCustomers()
  const customer = useSelectedCustomer()
  const { role } = useRole()
  const settings = useSettingsStore(state => state.globalSettings)

  const isMacOs = useIsMacOS()
  const shortcut = isMacOs ? 'Cmd' : intl.formatMessage(labelMessages.ctrl)
  const isDesktop = useIsDesktop()

  const toggle = () => setIsOpen(!isOpen)

  useKeyPress((e) => {
    if (role === OPERATOR && ['k'].includes(e.key) && (e.ctrlKey || e.metaKey)) {
      e.preventDefault() // Prevent default browser behavior
      toggle()
    }
  })

  const [triggerHidden, setTriggerHidden] = useState(false)
  // Hide the trigger for 30 seconds
  const hideTrigger = () => {
    setTriggerHidden(true)
    setTimeout(() => {
      setTriggerHidden(false)
    }, 30000)
  }

  const customerName = useMemo(() => {
    if (!customer || !data) return null
    return data.find((c) => c.id == customer)?.name
  }, [customer, data])

  if (role !== OPERATOR) return null
  const showTrigger = isDesktop
    ? settings.showCustomer == null ? true : settings.showCustomer
    : !triggerHidden

  return (
    <>
      {showTrigger && (
        <div className={s.root}>
          <div className={s.trigger}>
            <Button fullWidth labelTagName='div' labelClassName='w-full' className='text-ellipsis overflow-hidden whitespace-nowrap justify-between flex-row-reverse' size='xs' type='pure' icon={!isDesktop ? 'ChevronDown' : null} onClick={() => setIsOpen(!isOpen)}>
              <div className='flex items-center justify-between w-full space-x-2'>
                <div className='grow shrink overflow-hidden text-ellipsis'>{customerName}</div>
                {isDesktop && (
                  <div className='flex space-x-1'>
                    <Badge type={Badge.TYPES.fbInfo} size='xs' outline>{shortcut}</Badge>
                    <Badge type={Badge.TYPES.fbInfo} size='xs' outline>K</Badge>
                  </div>
                )}
              </div>
            </Button>
          </div>
        </div>
      )}
      <OperatorDialog onHideTrigger={hideTrigger} isOpen={isOpen} onClose={toggle} onSubmit={toggle} />
    </>
  )
}
