import { LOCALE_COOKIE, TOKEN_COOKIE } from 'constants/index'
import config from 'config'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import queryString from 'query-string'

const transformFormUrlEncoded = (body) => {
  const str = []
  for (const p in body) {
    const key = encodeURIComponent(p)
    const value = encodeURIComponent(body[p])
    str.push(`${key}=${value}`)
  }
  return str.join('&')
}

const transformFormData = (body) => {
  const formDataBody = new FormData()
  Object.keys(body).forEach((key) => {
    formDataBody.append(key, body[key])
  })
  return formDataBody
}

const prepareRequestHeaders = (
  cookies,
  contentType = 'application/json',
  token,
  nginxAuth
) => {
  const headers = {}
  if (nginxAuth) {
    headers.Authorization = `Basic ${nginxAuth}`
    if (token) {
      headers['X-API-Token'] = `Bearer ${token}`
    }
  } else {
    headers.Authorization = `Bearer ${token}`
  }
  headers.Accept = 'application/json'
  if (contentType !== 'multipart/form-data') {
    headers['Content-Type'] = contentType
  }
  const locale = cookies.get(LOCALE_COOKIE) || 'de-DE'
  headers.Lang = locale.substring(0, 2) // 'en'
  return headers
}

const prepareRequestBody = (body, contentType) => {
  if (contentType === 'application/x-www-form-urlencoded') {
    return transformFormUrlEncoded(body)
  } else if (contentType === 'multipart/form-data') {
    return transformFormData(body)
  } else {
    return JSON.stringify(body, (k, v) => (v === undefined ? null : v))
  }
}

function openFile (blob, fileName) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob])

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, fileName)
    return
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')
  link.href = data
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    document.body.removeChild(link)
    window.URL.revokeObjectURL(data)
  }, 100)
}

export const getRequestToken = (token, cookies) => {
  if (token) return token
  const impersonationToken = queryString.parse(window.location.search).impersonate
  if (impersonationToken) {
    // remove the token from the URL
    window.history.replaceState({}, document.title, window.location.pathname)
    useGeneralStore.setState({ token: impersonationToken })
    return impersonationToken
  }
  return useGeneralStore.getState().token || cookies.get(TOKEN_COOKIE)
}

/**
 * Creates a wrapper function around the HTML5 Fetch API that provides
 * default arguments to fetch(...) and is intended to reduce the amount
 * of boilerplate code in the application.
 * https://developer.mozilla.org/docs/Web/API/Fetch_API/Using_Fetch
 */
function createFetch (fetch, { cookies }) {
  const performFetch = async (url, { token, contentType, omitToken, ...options }) => {
    const authToken = omitToken ? null : getRequestToken(token, cookies)
    const anotherDomainRequest = url.startsWith('http')
    if (options.body) {
      options.body = prepareRequestBody(options.body, contentType)
    }
    if (!anotherDomainRequest) {
      options.headers = prepareRequestHeaders(
        cookies,
        contentType,
        authToken,
        config.nginxAuth
      )
    }
    return fetch(
      anotherDomainRequest ? url : `${config.api.url}${url}`,
      {
        ...options,
        headers: {
          ...options.headers
        }
      }
    )
  }

  const processResponse = async (resp, options, url) => {
    if (options.fileName) {
      if (resp.ok) {
        const responseFile = await resp.blob()
        openFile(responseFile, options.fileName)
        return { success: {} }
      } else {
        return { failure: {} }
      }
    } else {
      const responseText = await resp.text()
      if (responseText) {
        try {
          const currentVersion = {
            frontend_version: resp.headers.get('x-frontend-version'),
            backend_version: resp.headers.get('x-backend-version')
          }
          const { version, version_backend } = useGeneralStore.getState()
          if (version !== currentVersion.frontend_version || version_backend !== currentVersion.backend_version) {
            console.log('Versions changed, set new version in store')
            useGeneralStore.setState({ version: currentVersion.frontend_version, version_backend: currentVersion.backend_version })
          }
        } catch (ex) {
          console.log('Error while setting new version in store', ex)
        }

        if (resp.headers.get('content-type').includes('application/json')) {
          const responseBody = JSON.parse(responseText)
          // backend is not happy with our authentication token and we did not refreshed it.
          // To prevent spamming fetch error notifications everywhere, we hard redirect to the login page.
          if (resp.status === 401 && responseBody && responseBody.detail && responseBody.detail.includes('Authentication')) {
            if (cookies.get(TOKEN_COOKIE)) {
              cookies.remove(TOKEN_COOKIE, { path: '' })
            }
            // if we try to revoke while we aren't login, we don't care!
            if (!url.includes('/auth/revoke-token')) {
              window.location.href = '/login?reason=invalid_token&fromFetch=1'
            }
            return {
              refresh_token: true
            }
          }
          return resp.ok
            ? { success: responseBody }
            : { failure: responseBody }
        } else {
          return resp.ok
            ? { success: responseText }
            : { failure: responseText }
        }
      } else {
        return resp.ok ? { success: {} } : { failure: {} }
      }
    }
  }

  return async (url, options) => {
    try {
      const resp = await performFetch(url, options)
      const pResp = await processResponse(resp, options, url)

      if (pResp.success !== undefined) {
        return options.success(pResp.success)
      } else if (pResp.failure !== undefined) {
        return options.failure(pResp.failure)
      } else if (pResp.refresh_token !== undefined) {
        // FIXME: If we could directly fetch the token, we could redo the request, but this is not really possible at this position in the code
        return options.failure({ error: 'Authentication error' })
      }
    } catch (ex) {
      return options.failure({ error: ex.message })
    }
  }
}

export default createFetch
