import { defineMessages } from 'react-intl'

export default defineMessages({
  absolute: {
    id: 'chart.absolute',
    defaultMessage: 'absolute'
  },
  relative: {
    id: 'chart.relative',
    defaultMessage: 'in %'
  },
  comparisonPeriodTitle: {
    id: 'chart.comparisonPeriodTitle',
    defaultMessage: 'Comparison Period:'
  },
  comparisonPeriod: {
    id: 'chart.comparisonPeriod',
    defaultMessage: '{from} to {to}'
  },
  weekdayComparisonText: {
    id: 'chart.weekdayComparisonText',
    defaultMessage: 'The corresponding weekdays of the previous year are used as the comparison period.'
  },
  average: {
    id: 'chart.average',
    defaultMessage: 'Average'
  },
  avgTransactionRevenue: {
    id: 'chart.avgTransactionRevenue',
    defaultMessage: 'Ø Revenue / Transaction'
  },
  done: {
    id: 'chart.done',
    defaultMessage: 'Done'
  },
  late: {
    id: 'chart.late',
    defaultMessage: 'Overdue'
  },
  corrected: {
    id: 'chart.corrected',
    defaultMessage: 'Corrected'
  },
  manuallyAdded: {
    id: 'chart.manuallyAdded',
    defaultMessage: 'Manually Added'
  }
})
