import queryString from 'query-string'
import { useQueries } from '@tanstack/react-query'
import { useNetwork, usePrevious } from 'ahooks'
import { mapValues, omit, isEqual } from 'lodash'
import { useQueryFetcher, useUser } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { useEffect, useMemo, useState } from 'react'

const postProcess = (queryOpts, data) => {
  if (!data) return data

  const pData = !queryOpts.pagination && !Array.isArray(data) ? data.data : data

  if (!Array.isArray(data)) {
    if (!queryOpts.pagination && data.count !== data.total_count) {
      console.warn("useDataQuery: count and total_count don't match.", queryOpts, data)
    }
  }

  if (queryOpts.select && queryOpts.select.length === 1) {
    if (queryOpts.group_by) {
      if (!Array.isArray(queryOpts.group_by) || queryOpts.group_by.length === 1) {
        return mapValues(pData, (v) => v[0])
      } else if (queryOpts.group_by.length === 2) {
        return mapValues(pData, (v) => mapValues(v, (v2) => v2[0]))
      } else if (queryOpts.group_by.length === 3) {
        return mapValues(pData, (v) => mapValues(v, (v2) => mapValues(v2, (v3) => v3[0])))
      } else {
        throw new Error('useDataQuery fallback data getter does not support more than 3 group bys. It\'s time to refactor!')
      }
    } else {
      return pData[0]
    }
  }
  return pData
}

export const useDataQueries = (arr, options) => {
  const opts = {
    enabled: true,
    staleTime: undefined,
    refetchInterval: undefined,
    ...options
  }
  const networkState = useNetwork()
  const { fetch, token } = useQueryFetcher()
  const selectedCustomer = useSelectedCustomer()
  const { user } = useUser()

  const shouldBeEnabled = selectedCustomer != null && user != null && networkState.online && opts.enabled
  const results = useQueries({
    queries: arr.map((q) => {
      const body = omit(q, ['identifier'])
      return {
        queryKey: ['data-query', selectedCustomer, q.from, body],
        queryFn: () => new Promise((resolve, reject) => {
          fetch(
            `/data-query/?${queryString.stringify({
              customer: selectedCustomer
            })}`,
            {
              method: 'POST',
              token,
              body: body,
              success: (res) => resolve(postProcess(body, res)),
              failure: (err) => reject(err)
            }
          )
        }),
        staleTime: opts.staleTime,
        refetchInterval: opts.refetchInterval,
        enabled: shouldBeEnabled
      }
    })
  })

  const [storedResults, setStoredResults] = useState(results)
  const prevResults = usePrevious(results)
  useEffect(() => {
    // deep compare results and prevResults
    if (!isEqual(results, prevResults)) {
      setStoredResults(results)
    }
  }, [results])

  const overallStatus = useMemo(() => ({
    anyLoading: results.some(r => r.isLoading),
    anyFetching: results.some(r => r.isFetching),
    allSucceeded: results.every(r => r.isSuccess),
    anyError: results.some(r => r.isError),
    errors: results.map(r => r.isError ? r.error : null)
  }), [storedResults])

  return {
    results: storedResults,
    loading: overallStatus.anyError,
    fetching: overallStatus.anyFetching,
    success: overallStatus.allSucceeded,
    hasError: overallStatus.anyError,
    errors: overallStatus.errors
  }
}
