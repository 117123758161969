import { clone, find, findIndex, groupBy, keyBy, orderBy, sortBy } from 'lodash'
import { useDataQuery } from '..'
import { useEffect, useMemo } from 'react'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ReadyState } from 'react-use-websocket'
import { compareTaskCollection } from 'utils/comparer'
import { formatISO } from 'date-fns'
import { handleMutationError } from 'utils'
import message from 'services/message'
import queryString from 'query-string'
import { useInterval, useNetwork } from 'ahooks'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { useDebugStore } from 'hooks/store/useDebugStore'
import { useGeneralStore } from 'hooks/store/useGeneralStore'

export const useTodoQueries = (options) => {
  const opts = {
    enabled: true,
    ...options
  }

  const { fetch, token } = useQueryFetcher()
  const queryClient = useQueryClient()
  const selectedCustomer = useSelectedCustomer()
  const networkState = useNetwork()
  const logDebugMessage = useDebugStore(state => state.logDebugMessage)
  const socketState = useGeneralStore(state => state.socketState)

  const queryKey = ['todos', opts]

  const { data: tasksData, status: tasksStatus, error: tasksError, isLoading: tasksIsLoading, isFetching: tasksIsFetching, refetch: tasksRefetch } = useQuery({
    queryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/todos/?${queryString.stringify({
          customer: selectedCustomer,
          ...(opts.location ? { location: opts.location } : undefined),
          ...(opts.range ? { due_date: opts.range[0] } : undefined),
          ordering: 'due_by'
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res.results),
          failure: (err) => handleMutationError(err, reject)
        }
      )
    }),
    enabled: opts.enabled,
    placeholderData: keepPreviousData
  })

  const { data: items, error } = useDataQuery({
    from: 'Item',
    where: [
      {
        field: 'id',
        operator: 'in',
        value: tasksData ? tasksData.map((task) => task.item) : []
      }
    ]
  }, opts.enabled && tasksData != null && tasksData.length > 0)

  // This is a fallback since we have reports that the todo list is not automatically updating
  // Let's force refetch every minute and if we find data differences, we'll log them
  useInterval(() => {
    if (!networkState.online) {
      return
    }
    const currentTasks = clone(tasksData)
    tasksRefetch().then(({ data }) => {
      const diff = compareTaskCollection(currentTasks, data)
      if (diff.length > 0) {
        const connectionStatus = {
          [ReadyState.CONNECTING]: 'Connecting',
          [ReadyState.OPEN]: 'Open',
          [ReadyState.CLOSING]: 'Closing',
          [ReadyState.CLOSED]: 'Closed',
          [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
        }[socketState]
        logDebugMessage(`Todo Pull Action noted ${diff.length} data differences! Websocket: ${connectionStatus}`)
        logDebugMessage(diff)
        // trackEvent('todo_pull_ws_mismatch', { websocket: connectionStatus })
      }
    })
  }, 60000)

  const { mutateAsync: addMutation, isLoading: addLoading } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      fetch(
        `/todos/?${queryString.stringify({
          customer: selectedCustomer
        })}`,
        {
          method: 'POST',
          token,
          success: (res) => {
            resolve(res)
          },
          failure: (errors) => handleMutationError(errors, reject),
          body: data
        }
      )
    }),
    // TODO: Use optimistic updating simulate the new data before we have it
    onSettled: () => tasksRefetch()
  })

  const { mutateAsync: updateMutation, isLoading: updateLoading } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      fetch(
        `/todos/${data.id}/?${queryString.stringify({
          customer: selectedCustomer
        })}`,
        {
          method: 'PATCH',
          token,
          success: (res) => {
            resolve(res)
          },
          failure: (errors) => handleMutationError(errors, reject),
          body: data
        }
      )
    }),
    // We use optimistic updating to simulate the new data before we have it
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey })
      const previousData = queryClient.getQueryData(queryKey)
      const newData = [...previousData]

      const index = findIndex(newData, { id: data.id })
      if (index !== -1) {
        newData[index] = {
          ...newData[index],
          ...data
        }
      }
      queryClient.setQueryData(queryKey, newData)
      return { previousData }
    },
    onError: (_err, newData, context) => queryClient.setQueryData(queryKey, context.previousData),
    onSettled: () => tasksRefetch()
  })

  const triggerCompletion = (data) => {
    const previousData = queryClient.getQueryData(queryKey)
    const newData = [...previousData]

    const index = findIndex(newData, { id: data.id })
    if (index !== -1) {
      newData[index] = {
        ...newData[index],
        fulfilled_amount: data.fulfilled_amount,
        terminated_at: formatISO(new Date()),
        isCompleting: true
      }
    }
    queryClient.setQueryData(queryKey, newData)
    return new Promise((resolve) => {
      window.setTimeout(() => {
        updateMutation(data).then((value) => resolve(value))
      }, 3200)
    })
  }

  const triggerDeletion = (data) => {
    const previousData = queryClient.getQueryData(queryKey)
    const newData = [...previousData]

    const status = data.reason === 'missing-ingredients' ? 'blocked' : 'deleted'

    const index = findIndex(newData, { id: data.id })
    if (index !== -1) {
      newData[index] = {
        ...newData[index],
        terminated_at: formatISO(new Date()),
        status
      }
    }
    queryClient.setQueryData(queryKey, newData)
    return updateMutation({
      id: data.id,
      status
    })
  }

  const itemsById = useMemo(() => {
    if (items) {
      return keyBy(items, 'id')
    }
  }, [items])

  const extendedTasks = useMemo(() => {
    if (tasksData && itemsById) {
      return tasksData
        .map(task => ({
          ...task,
          itemId: task.item,
          item: itemsById[task.item]
        }))
    }
    return []
  }, [tasksData, itemsById])

  const sortTasks = (extendedTasks) => sortBy(extendedTasks, ['due_by', 'item.name', 'requested_amount'])

  const findById = (id) => find(extendedTasks, { id })

  const openTasks = useMemo(() => {
    if (extendedTasks) {
      return sortTasks(extendedTasks.filter(task => task.item != null && (task.status === 'open' || task.status === 'in_progress')))
    }
    return []
  }, [extendedTasks])

  const openTasksByCategory = useMemo(() => {
    if (openTasks) {
      return groupBy(openTasks, t => sortBy(t.todo_tags).join(' - '))
    }
  }, [openTasks])

  const doneTasks = useMemo(() => {
    if (extendedTasks) {
      return orderBy(
        extendedTasks
          .filter(task => task.status === 'done' || task.status === 'deleted'),
        ['terminated_at', 'asc'])
    }
    return []
  }, [extendedTasks])

  const doneTasksByCategory = useMemo(() => {
    if (doneTasks) {
      return groupBy(sortBy(doneTasks, ['item.name', 'terminated_at']), t => t.status === 'deleted' ? '_deleted' : sortBy(t.todo_tags).join(' - '))
    }
  }, [doneTasks])

  useEffect(() => {
    if (error) {
      if (typeof (error) === 'string') {
        message.error(error.split('\n')[0])
      } else {
        message.error('Error fetching data.')
      }
    }
  })

  return {
    tasks: {
      categories: openTasksByCategory ? sortBy(Object.keys(openTasksByCategory)) : [],
      doneCategories: doneTasksByCategory ? sortBy(Object.keys(doneTasksByCategory)) : [],
      openByCategory: openTasksByCategory,
      doneByCategory: doneTasksByCategory,
      done: doneTasks,
      status: tasksStatus,
      isFetching: tasksIsFetching,
      isLoading: tasksIsLoading || tasksIsFetching || extendedTasks?.length != tasksData?.length || (doneTasks?.length + openTasks?.length) != tasksData?.length,
      error: tasksError,
      findById
    },
    add: {
      mutateAsync: addMutation,
      isLoading: addLoading
    },
    update: {
      mutateAsync: updateMutation,
      isLoading: updateLoading
    },
    triggerCompletion,
    triggerDeletion
  }
}
