import PageActions from 'components/PageActions'
import { useIntl } from 'react-intl'
import { usePageSettings } from 'hooks/usePageSettings'
import titleMessages from 'components/titleMessages'
import { memo } from 'react'
import { PageFilters, pageSettingsToPageFilters } from 'components/PageFilters'
import { Filter } from 'hooks/useAvailableFilters'
import { isEqual } from 'lodash'
import { ActionButtons } from './ActionButtons'

const SettingsMap = {
  [Filter.ITEM_GROUP_1]: 'itemCategory1',
  [Filter.ITEM_GROUP_2]: 'itemCategory2',
  [Filter.OFFERING_GROUP_1]: 'og1',
  [Filter.OFFERING_GROUP_2]: 'og2',
  [Filter.OFFERING_GROUP_1_NAMES]: 'og1Names',
  [Filter.OFFERING_GROUP_2_NAMES]: 'og2Names',
  [Filter.FOODWASTE_CATEGORY]: 'wasteCategory',
  [Filter.WEEKDAY]: 'weekdays'
}

const Actions = ({ onHeightChange, columns }) => {
  const intl = useIntl()
  const { settings: filters, set } = usePageSettings()

  // support the old settings where location was a string
  const locationFilter = Array.isArray(filters.location) ? filters.location : [filters.location.toString()]

  const handleFilterChange = (filterKey, value) => {
    const settingsKey = SettingsMap[filterKey] || filterKey
    if (isEqual(value, filters[settingsKey]) || isEqual(value, locationFilter)) return
    set({
      [settingsKey]: value,
      ...(filters.pagination ? { pagination: { current: 1, pageSize: filters.pagination.pageSize || 25 } } : undefined)
    })
    // trackEvent('foodwaste_filter_changed', { settingsKey, value })
  }

  return (
    <PageActions
      onHeightChange={onHeightChange}
      title={intl.formatMessage(titleMessages.foodwaste)}
      actions={<ActionButtons columns={columns} />}
      helpText={intl.formatMessage({
        id: 'phrase.howtoFoodwaste',
        defaultMessage: 'How can I configure the food waste view?'
      })}
      helpLink='https://help.delicious-data.com/wie-kann-ich-die-food-waste-ansicht-konfigurieren'
    >
      <PageFilters
        settings={pageSettingsToPageFilters(filters, SettingsMap)}
        onChange={handleFilterChange}
      />
    </PageActions>
  )
}

export default memo(Actions)
