import { Transition } from '@headlessui/react'
import LoadingScreen from 'components/LoadingScreen'
import { useEffect, useMemo, useState } from 'react'
import { useDebounce, useDebounceEffect, useMount, usePrevious, useSize } from 'ahooks'
import { useDebugStore } from 'hooks/store/useDebugStore'
import { useQueryClient } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { init } from 'utils/init'
import { collectDebugInformation, submitDebugReport } from 'utils/debug'
import { useGeneralStore, useInitializationStore } from 'hooks/store'
import { useAppContext } from 'services/context'
import { useLocation, useNavigate } from 'react-router-dom'
import { SKIP_LOADING_ROUTES } from 'components/AppCore/AuthManager'
import { LOGIN_ROUTE } from 'routes'
import { MOBILE_BREAKPOINT, OPERATOR, TABLET_BREAKPOINT } from 'constants/index'
import { getRequestToken } from 'services/createFetch'
import { qFetchUser } from 'utils/queries'
import { CustomerSelector } from 'components/CustomerSelector'
import { PulseLogo } from 'components/PulseLogo'
import { isEqual } from 'lodash'

export const LoadingWrapper = ({ children }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const { cookies } = useAppContext()
  const { fetch } = useQueryFetcher()
  const logDebugMessage = useDebugStore(state => state.logDebugMessage)
  const initialized = useInitializationStore((state) => state.initialized)
  const toInitialize = useInitializationStore((state) => state.toInitialize)
  const setFinished = useInitializationStore((state) => state.setFinished)
  const finished = useInitializationStore((state) => state.finished)
  const [userObj, setUserObj] = useState(null)
  const deviceSize = useGeneralStore((state) => state.deviceSize)
  const setDeviceSize = useGeneralStore((state) => state.setDeviceSize)
  const setWindowSize = useGeneralStore((state) => state.setWindowSize)

  const startTimout = () => {
    const id = setTimeout(() => {
      logDebugMessage(`Loading Screen: Hard Refresh after 12 seconds! Loaded: ${initialized.join(',')}, to load: ${toInitialize.join(',')}  Refreshing page.`)
      submitDebugReport(collectDebugInformation(null, { type: `Loading Screen Hard refresh after 12 seconds, Loaded: ${initialized.join(',')}, to load: ${toInitialize.join(',')}` })).then(res => {
        // reload the page
        try {
          window.location.reload(true)
          location.href = location.origin + location.pathname
        } catch (e) { }
      })
    }, 12000)
    return id
  }

  const docBody = useMemo(() => {
    return document.body
  }, [])

  const windowSize = useSize(docBody)
  const debouncedSize = useDebounce(windowSize, { wait: 500 })

  useDebounceEffect(() => {
    if (!windowSize) {
      setDeviceSize({
        isMobile: false,
        isTablet: false,
        isDesktop: true
      })
    }

    const newSize = {
      isMobile: windowSize.width < MOBILE_BREAKPOINT,
      isTablet: windowSize.width >= MOBILE_BREAKPOINT && windowSize.width < TABLET_BREAKPOINT,
      isDesktop: windowSize.width >= TABLET_BREAKPOINT
    }

    setWindowSize(windowSize)
    if (!isEqual(deviceSize, newSize)) {
      setDeviceSize(newSize)
    }
  }, [debouncedSize], { wait: 500 })

  const prevFinished = usePrevious(finished)

  const triggerInitialization = (user) => {
    const id = startTimout()
    init(queryClient, fetch, cookies, logDebugMessage, user).then((result) => {
      clearTimeout(id)
      if (!result) {
        navigate(LOGIN_ROUTE, { replace: true })
      }
      setLoading(false)
    })
  }

  useMount(async () => {
    if (SKIP_LOADING_ROUTES.includes(pathname)) {
      setLoading(false)
      setFinished(true)
      return
    }

    logDebugMessage('Loading Screen mounted.')
    const requestToken = getRequestToken(null, cookies)
    if (!requestToken) {
      navigate(LOGIN_ROUTE, { replace: true })
      setLoading(false)
      return
    }
    const user = await qFetchUser(fetch)
    queryClient.setQueryData(['me', requestToken], user)
    useInitializationStore.getState().addLoaded('user', null, logDebugMessage)

    const customer = user.role === OPERATOR && window.location.hash && window.location.hash.includes('c_')
      ? parseInt(window.location.hash.split('c_')[1])
      : null

    if (user.role === OPERATOR && customer == null) {
      setUserObj(user)
    } else {
      triggerInitialization(user)
    }
  })

  const onCustomerSelected = (customerId) => {
    triggerInitialization(userObj)
    setUserObj(null)
  }

  useEffect(() => {
    if (prevFinished && !finished && !loading) {
      setLoading(true)
      const id = startTimout()
      init(queryClient, fetch, cookies, logDebugMessage).then((result) => {
        clearTimeout(id)
        if (!result) {
          navigate(LOGIN_ROUTE, { replace: true })
        }
        setLoading(false)
      })
    }
  }, [prevFinished, finished])

  return (
    <>
      <Transition
        show={loading}
        leave='transition-opacity duration-1000'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className='fixed inset-0 z-100 h-full w-full flex top-0 left-0 bg-white dura'
      >
        {userObj
          ? (
            <div className='flex flex-col justify-center items-center w-full max-w-lg mx-auto px-4 space-y-8'>
              <PulseLogo />
              <CustomerSelector autoOpen={windowSize.width >= TABLET_BREAKPOINT} onSelected={onCustomerSelected} />
            </div>
            )
          : <LoadingScreen />}
      </Transition>
      {!loading && children}
    </>
  )
}
