import { Button } from 'components/Button'
import { BAKERY, BUSINESS, STUDENT_UNION } from 'constants/index'

const getIcon = (customerType) => {
  switch (customerType) {
    case BAKERY:
      return 'FillingRate'
    case BUSINESS:
      return 'Industry'
    case STUDENT_UNION:
      return 'Location'
  }
}

export const QuickSelectButton = ({ customer, onClick }) => {
  return <Button type='secondary' icon={getIcon(customer.type)} onClick={() => onClick(customer)}>{customer.name}</Button>
}
