import { useMutation, useQuery } from '@tanstack/react-query'

import { handleMutationError, isEmpty } from 'utils'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import queryString from 'query-string'
import { useState } from 'react'

export const useInventory = (options) => {
  const opts = {
    enabled: true,
    ...options
  }

  const selectedCustomer = useSelectedCustomer()
  const [invalidItemOffering, setInvalidItemOffering] = useState(false)
  const { fetch, token } = useQueryFetcher()

  const queryKey = ['inventory', opts]

  const { data: inventoryData, status: inventoryStatus, isLoading: inventoryIsLoading, error: inventoryError } = useQuery({
    queryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/inventory/?${queryString.stringify({
          customer: selectedCustomer,
          location_id: opts.location,
          item_id: opts.itemId
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => {
            setInvalidItemOffering(false)
            if (typeof res === 'number') {
              resolve(Math.round(res))
            } else {
              resolve(res)
            }
          },
          failure: (err) => {
            if (err.includes('ItemOffering') && err.includes('does not exist')) {
              setInvalidItemOffering(true)
              reject(err)
            } else {
              handleMutationError(err, reject)
            }
          }
        }
      )
    }),
    enabled: opts.enabled === true && !isEmpty(opts.itemId),
    staleTime: 0,
    gcTime: 0,
    retry: 0
  })

  const { mutateAsync: updateMutation, isPending: updateIsPending } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      fetch(
        `/inventory/?${queryString.stringify({
          customer: selectedCustomer
        })}`,
        {
          method: 'POST',
          token,
          success: (res) => {
            resolve(res)
          },
          failure: (errors) => handleMutationError(errors, reject),
          body: data
        }
      )
    })
    // onSettled: () => {
    // trackEvent('updated_item_inventory', { itemId: opts.itemId })
    // }
  }
  )

  return {
    inventory: {
      data: inventoryData,
      isLoading: inventoryIsLoading,
      error: inventoryError,
      isInvalid: invalidItemOffering
    },
    update: {
      mutate: updateMutation,
      isPending: updateIsPending
    }
  }
}
