import { create } from 'zustand'
import { v4 as uuidv4 } from 'uuid'

export const useDashboardStore = create((set) => ({
  breakpoint: null,
  cachedItems: null,
  allDashboards: null,
  currentDashboard: null,
  layoutId: uuidv4(),
  overriddenFilters: {},
  // this is here because when viewing a dashboard the user will probably not
  // have access to all the locations. Those will be removed by the SearchSelect
  // in some dark magic way. We store the updated value as the viewOnlyDefault,
  // and reset to it when the user presses "Reset filters" in `ViewOnlyDialog`.
  viewOnlyDefaultFilters: {},
  setBreakpoint: (breakpoint) => set(() => ({ breakpoint })),
  setCachedItems: (cachedItems) => set(() => ({ cachedItems })),
  setCurrentDashboard: (currentDashboard) => set(() => ({ currentDashboard })),
  setAllDashboards: (allDashboards) => set(() => ({ allDashboards })),
  setLayoutId: (layoutId) => set(() => ({ layoutId })),
  setOverriddenFilters: (id, newOverriddenFilters) => set(({ overriddenFilters }) => {
    const o = { ...overriddenFilters }
    if (newOverriddenFilters === undefined || newOverriddenFilters === null) {
      delete o[id]
    } else {
      o[id] = newOverriddenFilters
    }
    return { overriddenFilters: o }
  }),
  setViewOnlyDefaultFilters: (id, newDefaultFilters) => set(({ viewOnlyDefaultFilters }) => {
    return { viewOnlyDefaultFilters: { ...viewOnlyDefaultFilters, [id]: newDefaultFilters } }
  })
}))
