import CustomerSelect from 'components/CustomerSelect'
import { QuickSelectButton } from './QuickSelectButton'
import { useQueryClient } from '@tanstack/react-query'
import { useCustomers } from 'hooks'
import { useDebugStore, useGeneralStore, useInitializationStore } from 'hooks/store'
import { useLocalStorageState } from 'ahooks'
import { addIdToArray } from 'utils'
import { useMemo } from 'react'
import { find } from 'lodash'

export const CustomerSelector = ({ className, user, onSelected, autoOpen }) => {
  const queryClient = useQueryClient()
  const { data } = useCustomers({ force: true })
  const logDebugMessage = useDebugStore(state => state.logDebugMessage)
  const setCustomer = useGeneralStore((state) => state.setCustomer)
  const resetInitialization = useInitializationStore((state) => state.reset)

  const [lastCustomers, setLastCustomers] = useLocalStorageState(
    'lastCustomers', { defaultValue: [] }
  )

  const reset = (newCustomer) => {
    resetInitialization(true)
    if (newCustomer) {
      setCustomer(newCustomer, user)
    }
    queryClient.removeQueries({ queryKey: ['sales-locations'] })
    if (onSelected) onSelected(newCustomer)
  }

  const onCustomerQuickSelect = (customer) => {
    // if customer is already the first in the list, do nothing, otherwise move it to the top or add it
    if (lastCustomers[0] !== customer.id) {
      const newLastCustomers = addIdToArray(lastCustomers, customer.id)
      setLastCustomers(newLastCustomers)
    }

    const selected = find(data, { id: customer.id })
    logDebugMessage(`Switching to customer ${selected.name} [${customer.id}]`)
    if (customer.id !== customer) {
      window.location.hash = `c_${customer.id}`
      reset(customer.id)
    }
  }

  const lastCustomerEntities = useMemo(() => {
    if (!data || !lastCustomers || lastCustomers.length === 0) return []
    return lastCustomers.map((cId) => find(data, { id: cId }))
  }, [lastCustomers, data])

  return (
    <div className={className}>
      <CustomerSelect onSelected={onSelected} side='top' autoOpen={autoOpen} />
      <div className='mt-2 grid grid-cols-2 gap-2'>
        {lastCustomerEntities.map((c) => <QuickSelectButton key={c.id} customer={c} onClick={onCustomerQuickSelect} />)}
      </div>
    </div>
  )
}
