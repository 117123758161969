import { FlexibleScaledNumberFormat } from 'components/FlexibleScaledNumberFormat'
import { GenericForm } from 'components/Form'
import labelMessages from 'components/labelMessages'
import { WEIGHT_VALUE_FORMAT } from 'constants/index'
import { FormattedMessage, useIntl } from 'react-intl'

export const SanityCheck = ({ data, checkResult, onCancel, onSubmit, loading }) => {
  const intl = useIntl()
  const initValues = {
    amount: data.amount
  }

  const handleSubmit = (values) => {
    onSubmit(values.amount)
  }

  const structure = [{
    fields: [
      {
        type: 'alert',
        alertType: 'warning',
        message: <FormattedMessage id='foodwaste.confirmMeasurementTitle' defaultMessage='Please confirm the entered measurement' />,
        description: (
          <FormattedMessage
            id='foodwaste.confirmMeasurementDesc'
            defaultMessage='Are you sure you typed the correct value? Usually values lie between {I_10} and {I_90} but you enterered {value}.'
            values={{
              I_10: <strong><FlexibleScaledNumberFormat key='number' value={checkResult.iq10} {...WEIGHT_VALUE_FORMAT} /></strong>,
              I_90: <strong><FlexibleScaledNumberFormat key='number' value={checkResult.iq90} {...WEIGHT_VALUE_FORMAT} /></strong>,
              value: <strong><FlexibleScaledNumberFormat key='number' value={data.amount} {...WEIGHT_VALUE_FORMAT} /></strong>
            }}
          />
        )
      },
      {
        type: 'number-format',
        label: intl.formatMessage(labelMessages.measurement),
        name: 'amount',
        decimalSeparator: ',',
        decimalScale: 2,
        suffix: ' kg'
      }
    ]
  }]

  return (
    <GenericForm
      asModal
      title={<FormattedMessage id='foodwaste.confirmMeasurement' defaultMessage='Confirm Measurement' />}
      structure={structure}
      initialValues={initValues}
      visible
      onSubmit={handleSubmit}
      onCancel={onCancel}
      okText={<FormattedMessage id='foodwaste.confirmMeasurement' defaultMessage='Confirm Measurement' />}
      confirmLoading={loading}
    />
  )
}
