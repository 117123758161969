import { useChartGrouping, useDashboardRefreshInterval, useDataQuery } from '..'

import message from 'services/message'
import notifyMessages from 'components/notifyMessages'
import { useEffect, useMemo } from 'react'
import { useInterval } from 'ahooks'
import { useIntl } from 'react-intl'
import { useUI } from 'services/UIProvider'
import { usePageSettings } from 'hooks/usePageSettings'
import { useChartQueryDebugger } from 'hooks/useChartQueryDebugger'
import { useChartQueryResultSetter } from 'hooks/useChartQueryResultSetter'
import globalMessages from 'components/globalMessages'
import { shouldFilterWeekdays } from 'utils'
import { Filter } from 'hooks/useAvailableFilters'
import { useQueryClient } from '@tanstack/react-query'

export const useChartData = (identifier, dataOptions, chartDataMethod, throwOnError, enabled = true) => {
  const intl = useIntl()
  const { resetChartErrorKey } = useUI()
  const queryClient = useQueryClient()
  const { onDebugClick, setResponse, response, setResult, result } = useChartQueryDebugger(identifier, { dataOptions })
  const isEnabled = enabled && dataOptions != null && Object.keys(dataOptions).length > 0
  const { data, isFetching, refetch, loading, error, queryKey } = useDataQuery({
    identifier,
    ...dataOptions
  }, isEnabled, null, {
    staleTime: 300000
  })
  const groupBy = useChartGrouping(data)
  const { settings } = usePageSettings()

  const chartData = useMemo(() => chartDataMethod ? chartDataMethod(data || {}, groupBy, settings[Filter.DATE_RANGE]?.value, intl.formatMessage(globalMessages.other), shouldFilterWeekdays(settings.weekdays) ? settings.weekdays : null) : data, [chartDataMethod, data, groupBy, settings[Filter.DATE_RANGE]])
  const interval = useDashboardRefreshInterval()
  useInterval(() => {
    if (isEnabled) {
      refetch()
      window.setTimeout(() => resetChartErrorKey(), 500)
    }
  }, interval)

  useChartQueryResultSetter(setResponse, { data })

  useEffect(() => {
    if (!loading && error) {
      const m = intl.formatMessage(notifyMessages.chartError, { identifier: identifier, type: 'data', message: typeof (error) === 'object' ? error.message || error.error : error })

      if (throwOnError) {
        queryClient.removeQueries({ queryKey })
        throw new Error(m)
      } else {
        message.error(m, 15)
        console.error(m)
      }
    }
  }, [loading, error])

  return {
    loading: isFetching,
    chartData,
    realGroupBy: groupBy,
    onDebugClick,
    response,
    setResult,
    result
  }
}
