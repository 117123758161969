import classNames from 'classnames'
import { fromDate } from '@internationalized/date'
import { useRangeCalendarState } from 'react-stately'
import { useMemo, useRef, useState } from 'react'
import { useRangeCalendar } from 'react-aria'
import { Button } from 'components/Button'
import s from './Calendar.module.scss'
import { formatLocalized } from 'utils/datetime'
import { useIntl } from 'react-intl'
import Heading from 'components/Heading'
import { RangePreview } from 'components/RangePreview'
import { CalendarGrid } from './Partials/CalendarGrid'
import { createCalendar } from './utils'

// Also see https://codesandbox.io/p/sandbox/objective-shape-8r4utm?file=%2Fsrc%2FCalendar.js for examples

/**
 * This component renders a simple range calendar to select a range
 */
export const RangeCalendar = ({ vertical, months, className, value, onChange, maxValue, minValue, noPreview, ...props }) => {
  const intl = useIntl()
  const internalValue = value
    ? {
        start: fromDate(typeof value[0] === 'string' ? new Date(value[0]) : value[0]),
        end: fromDate(typeof value[1] === 'string' ? new Date(value[1]) : value[1])
      }
    : undefined

  const [selectedRange, setSelectedRange] = useState(value)
  const [focusStart, setFocusStart] = useState(null)
  const [currentFocus, setCurrentFocus] = useState(null)
  const onRangeChange = (range) => {
    const r = [
      range.start.toDate(),
      range.end.toDate()
    ]
    setSelectedRange(r)
    setFocusStart(null)
    setCurrentFocus(null)
    if (!onChange) return
    onChange(r)
  }

  const onFocusChange = (date) => {
    if (!focusStart) {
      setFocusStart(date.toDate())
    } else {
      setCurrentFocus(date.toDate())
    }
  }

  const p = {
    ...props,
    maxValue: maxValue ? fromDate(maxValue) : undefined,
    minValue: minValue ? fromDate(minValue) : undefined,
    value: internalValue,
    onChange: onRangeChange,
    onFocusChange: onFocusChange
  }

  const previewedRange = useMemo(() => {
    if (focusStart) {
      const c = currentFocus || focusStart
      return [focusStart, c]
    }
    return selectedRange
  }, [focusStart, currentFocus, selectedRange])

  const state = useRangeCalendarState({
    ...p,
    // this fucking bullshit is completly ignoring our given locale, but it crashes if I don't pass it. 🤦
    locale: intl.locale.substring(0, 2),
    createCalendar
  })

  const ref = useRef(null)
  const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(p, state, ref)

  return (
    <div {...calendarProps} ref={ref} className={classNames(s.root, className)}>
      <div className='flex w-full justify-between items-center mb-4'>
        <Button disabled={prevButtonProps.isDisabled} onPointerDown={prevButtonProps.onPress} type='secondary' size='s' icon='ChevronLeft' data-action='prevMonth' />
        <Heading type='h5' data-cy='month-header'>{formatLocalized(state.visibleRange.start.toDate(), 'MMMM yyyy', intl)}</Heading>
        <Button disabled={nextButtonProps.isDisabled} onPointerDown={nextButtonProps.onPress} type='secondary' size='s' icon='ChevronRight' data-action='nextMonth' />
      </div>
      <CalendarGrid state={state} />
      {!noPreview && (
        <div className='mt-4 mb-2 flex justify-center'>
          <RangePreview range={previewedRange} />
        </div>
      )}
    </div>
  )
}
