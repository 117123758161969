import { DFNS_DATE_FORMAT } from 'constants/index'
import { format, parseISO } from 'date-fns'
import { useUser } from '.'

import messageService from 'services/message'
import notifyMessages from 'components/notifyMessages'
import { find, intersection, omit } from 'lodash'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from '@tanstack/react-query'
import useWebSocket from 'react-use-websocket'
import { useSelectedCustomer } from './useSelectedCustomer'
import { useScriptContext } from 'services/ScriptProvider'
import { useGeneralStore } from './store/useGeneralStore'
import config from 'config'
import { useDebugStore } from './store/useDebugStore'
import { useDataStore } from './store/useDataStore'

const extractLocations = (queryKey) => {
  let locations = []
  if (queryKey[3] && queryKey[3].sales_locations) {
    locations = queryKey[3].sales_locations.map(i => parseInt(i))
  } else if (queryKey[2] && queryKey[2].where) {
    const field = find(queryKey[2].where, { field: 'sales_location' })
    if (field) {
      locations = [parseInt(field.value)]
    }
  }
  return locations
}

const wsUrl = config.api.wsUrl

const useWebSocketNotifications = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const selectedCustomer = useSelectedCustomer()
  const { user } = useUser()
  const token = useGeneralStore(state => state.token)
  const setAppVersion = useGeneralStore(state => state.setVersion)
  const { handleResponse } = useScriptContext()
  const [reconnectAttempts, setReconnectAttempts] = useState(1)
  const logDebugMessage = useDebugStore(state => state.logDebugMessage)
  const setDataFreshness = useDataStore(state => state.setDataFreshness)

  const socketEnabled = useGeneralStore(state => state.socketEnabled)
  const setSocketState = useGeneralStore(state => state.setSocketState)

  const url = socketEnabled && token && user ? `${wsUrl}/production-data/notifications?access_token=${token}` : null

  const {
    lastJsonMessage: message,
    readyState
  } = useWebSocket(url, {

    shouldReconnect: (closeEvent) => {
      const reconnect = user != null
      if (reconnect) {
        console.warn(`WebSocket connection for user ${user ? user.email : 'null'} is closed, reconnecting attempt ${reconnectAttempts}…`, closeEvent)
        logDebugMessage(`WebSocket connection for user ${user ? user.email : 'null'} is closed, reconnecting attempt ${reconnectAttempts}…`)
      } else {
        console.warn(`WebSocket connection for user ${user ? user.email : 'null'} is closed due to logout.`, closeEvent)
        logDebugMessage(`WebSocket connection for user ${user ? user.email : 'null'} is closed due to logout.`)
      }
      return reconnect
    },
    onOpen: () => {
      logDebugMessage(`WebSocket connection opened for user ${user ? user.email : 'null'} after ${reconnectAttempts} attempts.`)
      setReconnectAttempts(1)
    },
    onError: (event) => {
      logDebugMessage(`WebSocket connection error for user ${user ? user.email : 'null'}.`)
      console.warn(event)
    },
    onReconnectStop: (attempts) => {
      logDebugMessage(` WebSocket connection stopped reconnecting after ${attempts} attempts.`)
    },
    // pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 30 seconds
    reconnectInterval: (attemptNumber) => {
      setReconnectAttempts(attemptNumber + 2)
      return Math.min(Math.pow(2, attemptNumber) * 1000, 30000)
    },
    reconnectAttempts: Infinity,
    retryOnError: true
  })

  const invalidateOfferingViews = (affectedLocations) => {
    if (!affectedLocations || affectedLocations.length === 0) return

    queryClient.invalidateQueries({
      predicate: query => {
        if (query.queryKey[0] !== 'offerings-table' && query.queryKey[0] !== 'offerings-grid') {
          return false
        }

        const locations = extractLocations(query.queryKey)

        const oTable = query.queryKey[0] === 'offerings-table' &&
          query.queryKey[1] == selectedCustomer &&
          intersection(locations, affectedLocations).length > 0

        const oGrid = query.queryKey[0] === 'offerings-grid' &&
          query.queryKey[1] === 'data-query' &&
          query.queryKey[2].selectedCustomer == selectedCustomer &&
          intersection(locations, affectedLocations).length > 0

        return oTable || oGrid
      }
    })
  }

  useEffect(() => setSocketState(readyState), [readyState])

  useEffect(() => {
    if (!message) { return }
    if (message.code !== 'new_version' && message.code !== 'current_version' && (message.code !== 'scripts.response' && message.customer != selectedCustomer)) {
      return
    }

    let payload
    if (!message.payload) {
      console.warn('No payload in message', message)
      payload = {
        ...omit(message, ['code', 'customer']),
        ...(message.sales_locations ? { locations: message.sales_locations } : undefined)
      }
    } else {
      payload = {
        ...message.payload,
        ...(message.payload.sales_locations ? { locations: message.payload.sales_locations } : undefined)
      }
    }

    try {
      switch (message.code) {
        case 'todo':
          // invalidate todo query if selected range and location are matching
          queryClient.invalidateQueries({
            predicate: query => query.queryKey[0] === 'todos' &&
              query.queryKey[1].range[0] === format(parseISO(payload.due_by), DFNS_DATE_FORMAT) &&
              (payload.locations.includes(parseInt(query.queryKey[1].location)) || payload.locations.includes(query.queryKey[1].location))
          })
          break
        case 'file_import':
          queryClient.invalidateQueries({ queryKey: ['sales-data-files'] })
          if (payload.status === 'failed') {
            messageService.error(intl.formatMessage(notifyMessages.importingError))
          } else if (payload.status === 'success') {
            invalidateOfferingViews(message.payload.locations)
          }
          break
        case 'forecast':
          if (payload.status === 'success') {
            invalidateOfferingViews(message.payload.locations)
          }
          break
        case 'current_version':
        case 'new_version':
          console.info(`Received ${message.code}`, message.payload)
          setAppVersion(message.payload)
          break
        case 'scripts.response':
          handleResponse(message)
          break
        case 'data_freshness':
          setDataFreshness(message.payload)
          break
        default:
          console.warn('Received unhandled websocket message:', message)
          break
      }
    } catch (ex) {
      console.error('Error while handling websocket message', ex)
      console.log('Message:', message)
    }
  }, [message])
}

export default useWebSocketNotifications
