import queryString from 'query-string'
import { useNetwork } from 'ahooks'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { mapValues, omit } from 'lodash'
import { useMemo } from 'react'
import { useQueryFetcher, useUser } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'

export const useDataQuery = (options, enabled = true, postProcessMethod = null, additionalQueryOptions = null) => {
  const networkState = useNetwork()
  const queryOpts = {
    cacheKey: 'data-query',
    identifier: 'data-query',
    ...options
  }

  const { fetch, token } = useQueryFetcher()
  const selectedCustomer = useSelectedCustomer()
  const { user } = useUser()

  if (queryOpts.function) {
    throw new Error('Function is not supported anymore. Use the new select syntax instead')
  }

  const postBody = {
    ...(queryOpts.select ? { select: queryOpts.select } : undefined),
    ...(queryOpts.from ? { from: queryOpts.from } : undefined),
    ...(queryOpts.order_by ? { order_by: queryOpts.order_by } : undefined),
    ...(queryOpts.group_by ? { group_by: queryOpts.group_by } : undefined),
    ...(queryOpts.where && queryOpts.where.length > 0 ? { where: queryOpts.where.filter(i => i) } : undefined),
    ...(queryOpts.limit != null ? { limit: queryOpts.limit } : undefined)
  }

  const shouldBeEnabled = selectedCustomer != null && user != null && networkState.online && enabled
  let prevent = false
  if (shouldBeEnabled && Object.keys(postBody).length === 0) {
    prevent = true
  }

  const opts = {
    placeholderData: keepPreviousData,
    enabled: shouldBeEnabled && !prevent,
    retry: false,
    ...additionalQueryOptions
  }

  const queryKey = [queryOpts.cacheKey, queryOpts.identifier, { selectedCustomer, ...omit(queryOpts, ['cacheKey', 'identifier']) }]
  const { data, status, error, isFetching, isLoading, isPending, refetch } = useQuery({
    queryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/data-query/?${queryString.stringify({
          customer: selectedCustomer
        })}`,
        {
          method: 'POST',
          token,
          body: postBody,
          success: (res) => {
            if (postProcessMethod) { resolve(postProcessMethod(res)) } else { resolve(res) }
          },
          failure: (err) => reject(err)
        }
      )
    }),
    ...opts
  })

  // return type changed. This is a workaround when one column was selected or we don't need pagination yet,
  // so we don't have to change all the code
  const fallBackData = useMemo(() => {
    if (!data) return data

    const pData = !queryOpts.pagination && !Array.isArray(data) ? data.data : data

    if (!Array.isArray(data)) {
      if (!queryOpts.pagination && data.count !== data.total_count) {
        console.warn("useDataQuery: count and total_count don't match.", postBody, data)
      }
    }

    if (queryOpts.select && queryOpts.select.length === 1) {
      if (queryOpts.group_by) {
        if (!Array.isArray(queryOpts.group_by) || queryOpts.group_by.length === 1) {
          return mapValues(pData, (v) => v[0])
        } else if (queryOpts.group_by.length === 2) {
          return mapValues(pData, (v) => mapValues(v, (v2) => v2[0]))
        } else {
          throw new Error('useDataQuery fallback data getter does not support more than 2 group bys. It\'s time to refactor!')
        }
      } else {
        return pData[0]
      }
    }
    return pData
  }, [data])

  // Using this new method crashes in some cases with option.props is undefined.
  // Keeping the old method for now
  // const fallBackData = useMemo(() => postProcessDataQueryFallbackData(queryOpts, data), [data])

  return { data: fallBackData, status, error, isFetching, isLoading, isPending, refetch, queryKey }
}
