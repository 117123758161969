import { useLocations, useOfferingGroups } from '.'

import { find } from 'lodash'
import { usePageSettings } from 'hooks/usePageSettings'
import labelMessages from 'components/labelMessages'
import { Filter } from './useAvailableFilters'

export const MENULINE = 'menuline'
export const COMPONENT = 'component'
export const LOCATION = 'sales_location__name'
export const ITEM = 'item__name'
export const CATEGORY1 = 'item__category_1'
export const CATEGORY2 = 'item__category_2'
export const FOODWASTE_CATEGORY = 'category__name'

export const TODO_ITEM = 'item__name'
export const TODO_LOCATION = 'location__name'
export const TODO_TAGS = 'item__todo_tags'

export const useChartTabs = (givenTab, options) => {
  const opts = {
    type: 'default',
    ...options
  }
  const { settings } = usePageSettings()
  const location = settings.location
  const { data: allLocations } = useLocations()
  const ogLocFilter = allLocations != null && Array.isArray(location) ? !(!location.length || location.length === allLocations.length) ? location : null : [location]
  const { data: offeringGroups1 } = useOfferingGroups({
    offeringGroupNo: 1,
    locations: ogLocFilter,
    range: settings[Filter.DATE_RANGE]?.value,
    enabled: allLocations != null
  })
  const { data: offeringGroups2 } = useOfferingGroups({
    offeringGroupNo: 2,
    locations: ogLocFilter,
    range: settings[Filter.DATE_RANGE]?.value,
    enabled: allLocations != null
  })

  const hasItemNames = true // useSelector(selectHasItemNames)
  const hasCategory1 = true // useSelector(selectHasPrimaryItemCategories)
  const hasCategory2 = true // useSelector(selectHasSecondaryItemCategories)

  const tabs = [
    ...(allLocations && allLocations.length > 1 ? [{ value: opts.type === 'todo' ? TODO_LOCATION : LOCATION, label: labelMessages.location }] : []),
    ...(opts.type !== 'todo' && offeringGroups1 && offeringGroups1.length > 0 ? [{ value: MENULINE, label: labelMessages.offeringGroup1 }] : []),
    ...(opts.type !== 'todo' && offeringGroups2 && offeringGroups2.length > 0 ? [{ value: COMPONENT, label: labelMessages.offeringGroup2 }] : []),
    ...(hasCategory1 ? [{ value: CATEGORY1, label: labelMessages.itemGroup1 }] : []),
    ...(hasCategory2 ? [{ value: CATEGORY2, label: labelMessages.itemGroup2 }] : []),
    ...(hasItemNames ? [{ value: opts.type === 'todo' ? TODO_ITEM : ITEM, label: labelMessages.item }] : [])
    // ...(opts.type === 'todo' ? [{ value: TODO_CATEGORY, label: globalMessages.todoCategory }] : []) // disable todo category since these are now tags
  ]
  return {
    tabs,
    // if given tab is not in the list of tabs, use the first one
    tab: tabs.length === 0 ? null : find(tabs, { value: givenTab }) ? givenTab : tabs[0].value
  }
}
