import { Text } from '../Text'

export const ExternalLink = ({ id, onClick, href, size, className, children, noTarget }) => (
  <Text
    tagName='a'
    id={id}
    onClick={onClick}
    type='link'
    size={size}
    href={href}
    target={noTarget ? undefined : '_blank'}
    className={className}
  >
    {children}
  </Text>
)
