import { GenericForm } from 'components/Form'
import labelMessages from 'components/labelMessages'
import { useDemo, useIsDesktop, useUser } from 'hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSettingsStore } from 'hooks/store'
import useIsMacOS from 'hooks/useIsMacOS'
import { setSetting } from 'services/SettingsProvider'
import { CustomerSelector } from 'components/CustomerSelector'
import { Button } from 'components/Button'

export const OperatorDialog = ({ isOpen, onClose, onHideTrigger }) => {
  const intl = useIntl()

  const { user } = useUser()
  const { enabled, toggle } = useDemo()
  const settings = useSettingsStore(state => state.globalSettings)

  const isMacOs = useIsMacOS()
  const shortcut = isMacOs ? 'Cmd' : intl.formatMessage(labelMessages.ctrl)
  const isDesktop = useIsDesktop()

  const initValues = {
    demo: enabled,
    showCustomer: settings.showCustomer == null ? true : settings.showCustomer
  }

  const onSubmit = (values) => {
    if (values.demo !== initValues.demo) {
      toggle()
    }

    if (values.showCustomer !== initValues.showCustomer) {
      setSetting({ showCustomer: values.showCustomer }, true)
    }

    onClose()
  }

  const structure = [{
    fields: [
      {
        type: 'component',
        label: intl.formatMessage(labelMessages.customer),
        render: () => (<CustomerSelector user={user} autoOpen={isDesktop} />),
        name: 'customer'
      },
      ...(isDesktop
        ? [
            {
              type: 'boolean',
              label: <FormattedMessage id='operatorOptions.showCustomer' defaultMessage='Show Customer' />,
              name: 'showCustomer',
              helpText: <FormattedMessage id='operatorOptions.showCustomerHelpText' defaultMessage='Define if you want to always show the current customer at the top. If you hide it, you can always open this dialog using the shortcut {super}+K.' values={{ super: shortcut }} />
            }
          ]
        : [
            {
              type: 'component',
              label: <FormattedMessage id='operatorOptions.showCustomer' defaultMessage='Show Customer' />,
              name: 'showCustomer',
              render: () => (
                <Button
                  type='secondary' onClick={() => {
                    onHideTrigger()
                    onClose()
                  }}
                >
                  <FormattedMessage id='operatorOptions.hideCustomer' defaultMessage='Hide Customer Indicator' />
                </Button>
              ),
              helpText: <FormattedMessage id='operatorOptions.hideCustomerHelpText' defaultMessage='Press the button to hide the customer indicator for 30 seconds.' />
            }
          ]
      ),
      {
        type: 'boolean',
        label: <FormattedMessage id='operatorOptions.demoMode' defaultMessage='Demo Mode' />,
        name: 'demo',
        helpText: <FormattedMessage id='operatorOptions.demoModeHelpText' defaultMessage='The demo mode hides confidential information from the UI. This selection is not saved permamently.' />
      }
    ]
  }]

  return (
    <GenericForm
      asModal
      visible={isOpen}
      structure={structure}
      initialValues={initValues}
      title={<FormattedMessage id='operatorOptions.title' defaultMessage='Operator Options' />}
      onCancel={onClose}
      onSubmit={onSubmit}
    />
  )
}
