import classNames from 'classnames'
import s from './Alert.module.scss'
import { Icon, Text } from 'components/Primitives'
import { Button } from 'components/Button'
import { useState } from 'react'

export const Alert = ({ type, size, message, description, showIcon, className, banner, closable }) => {
  let icon
  switch (type) {
    case 'info':
    default:
      icon = description ? 'Information' : 'InformationFilled'
      break
    case 'success':
      icon = description ? 'CheckmarkOutline' : 'CheckmarkFilled'
      break
    case 'warning':
      icon = description ? 'Warning' : 'WarningFilled'
      break
    case 'error':
      icon = description ? 'MisuseOutline' : 'Misuse'
      break
  }

  const [closed, setClosed] = useState(false)

  return (
    <div className={classNames(className, s.alert, s[type || 'info'], banner && s.banner, message && description && s.hasDescription, closable && s.closable, closed && s.closed)}>
      {showIcon && <Icon name={icon} className={classNames(s.icon)} />}
      <div className={s.content}>
        {message && <Text className={description && 'mb-1 inline-block'} color='black' size={size || 's'} bold={!!description}>{message}</Text>}
        {description && <p>{description}</p>}
      </div>
      {closable && <Button className={s.close} icon='Close' type='neutral' pure size={description ? 'm' : 's'} onClick={() => setClosed(true)} />}
    </div>
  )
}
