import { useAvailableFilters, getFilterProps } from 'hooks/useAvailableFilters'
import { Filter } from 'constants/index'
import { ItemGroupPicker, ItemPicker, LocationPicker, OfferingGroupPicker, WeekdayPicker, WasteCategoryPicker, BooleanPicker, TagPicker } from 'components/Pickers'
import DateTimePicker from 'components/DateTimePicker'
import RangePicker from 'components/RangePicker'
import { RangePicker as RangePicker2 } from 'components/Pickers/RangePicker'
import { useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import labelMessages from 'components/labelMessages'
import { DASHBOARD_ROUTE, FORECASTS_ROUTE, ORDERS_ROUTE } from 'routes'
import globalMessages from 'components/globalMessages'
import { memo, useEffect, useMemo, useState } from 'react'
import { isEqual, omit } from 'lodash'
import { Button } from 'components/Button'
import { getRangePickerPresets } from 'hooks/usePageSettings'
import { mergeFilters } from 'components/Report/utils'
import { calculateFloatingRange } from 'utils/datetime'
import { isNullOrEmptyArray } from 'utils'
import { ComparisonPeriodPicker } from 'components/Pickers/ComparisonPeriodPicker'

const renderFilter = (filter, pathname, filters, referenceFilters, localFilters, intl, onChange, inModal, v1, disabled, definitionId, onValidationChange) => {
  const filterKey = typeof (filter) === 'string' ? filter : filter.key
  const props = typeof (filter) === 'string' ? {} : filter.props
  const commonProps = {
    key: filterKey,
    withPortal: !inModal,
    type: inModal ? 'templates' : undefined,
    value: filters[filterKey],
    onChange: (v) => onChange(filterKey, v),
    onValidationChange: onValidationChange ? (o) => onValidationChange(filterKey === 'locations' ? 'location' : filterKey, o) : undefined,
    disabled
  }
  const referenceProps = {
    range: referenceFilters
      ? typeof referenceFilters[Filter.DATE_RANGE]?.value === 'object' && !Array.isArray(referenceFilters[Filter.DATE_RANGE]?.value)
          ? calculateFloatingRange(referenceFilters[Filter.DATE_RANGE]?.value, true)
          : referenceFilters[Filter.DATE_RANGE]?.value
      : null,
    location: referenceFilters?.location,
    offeringGroup1: referenceFilters?.offeringGroup1Names,
    offeringGroup2: referenceFilters?.offeringGroup2Names,
    itemGroup1: referenceFilters?.itemGroup1,
    itemGroup2: referenceFilters?.itemGroup2
  }

  switch (filterKey) {
    case Filter.LOCATIONS:
      return <LocationPicker {...commonProps} basicPlaceholder value={filters.location} onChange={(location) => onChange('location', location)} {...props} />
    case Filter.LOCATION:
      // We messed up...
      if (pathname.startsWith(DASHBOARD_ROUTE)) {
        return <LocationPicker {...commonProps} basicPlaceholder value={filters.location} onChange={(location) => onChange('location', location)} {...props} />
      }
      return <LocationPicker {...commonProps} required single basicPlaceholder {...props} />
    case Filter.DATE_RANGE:
      // FIXME: At some point we want to replace the old picker on all other pages
      if (pathname.startsWith(DASHBOARD_ROUTE)) {
        return (
          <RangePicker2
            key='rp2'
            {...commonProps}
            onChange={(value, option) => onChange(filterKey, { value, option })}
            value={filters[filterKey]?.value}
            option={filters[filterKey]?.option}
            comparisonPeriodPickerKey={inModal ? 'templates' : 'dashboard'}
            {...props}
            allowReset={inModal}
          />
        )
      } else {
        return (
          <RangePicker
            key='rp'
            {...commonProps}
            onChange={(value, option) => onChange(filterKey, { value, option })}
            value={filters[filterKey]?.value}
            option={filters[filterKey]?.option}
            {...props}
          />
        )
      }
    case Filter.COMPARISON:
      return !v1 && (
        <ComparisonPeriodPicker
          key='cp'
          {...commonProps}
          onChange={(value) => onChange(filterKey, value)}
          value={filters[filterKey]}
          comparisonPeriodPickerKey={inModal ? `templates-${definitionId}` : 'dashboard'}
          {...props}
        />
      )
    case Filter.WEEKDAY:
      return <WeekdayPicker key='w' {...commonProps} {...props} />
    case Filter.ITEM:
      return (
        <ItemPicker
          key='i'
          {...referenceProps}
          {...commonProps}
          fromItemOffering={pathname.startsWith(DASHBOARD_ROUTE) || pathname === FORECASTS_ROUTE}
          fromOrder={pathname.startsWith(DASHBOARD_ROUTE) || pathname === ORDERS_ROUTE}
          hideWhenEmpty
          gray={inModal}
          {...props}
          v1={v1}
        />
      )
    case Filter.ITEM_GROUP_1:
      return <ItemGroupPicker key='ig1' {...commonProps} categoryNo={1} {...referenceProps} {...props} />
    case Filter.ITEM_GROUP_2:
      return <ItemGroupPicker key='ig2' {...commonProps} categoryNo={2} {...referenceProps} {...props} />
    case Filter.OFFERING_GROUP_1:
      return <OfferingGroupPicker key='og1' {...commonProps} groupNumber={1} {...referenceProps} {...props} />
    case Filter.OFFERING_GROUP_2:
      return <OfferingGroupPicker key='og2' {...commonProps} groupNumber={2} {...referenceProps} {...props} />
    case Filter.OFFERING_GROUP_1_NAMES:
      return <OfferingGroupPicker key='og1n' {...commonProps} groupNumber={1} asNames {...referenceProps} {...props} />
    case Filter.OFFERING_GROUP_2_NAMES:
      return <OfferingGroupPicker key='og2n' {...commonProps} groupNumber={2} asNames {...referenceProps} {...props} />
    case Filter.ORDER_ACTIVE:
      return (
        <BooleanPicker
          key='b1'
          {...commonProps}
          asActive
          placeholder={<FormattedMessage {...labelMessages.order} />}
          prefixText={<FormattedMessage {...labelMessages.order} />}
          noMinWidth
          className='min-w-9'
          {...props}
        />
      )
    case Filter.FOODWASTE_CATEGORY:
      return <WasteCategoryPicker key='wc' {...commonProps} {...referenceProps} {...props} />
    case Filter.STATUS:
      return (
        <BooleanPicker
          key='b2'
          value={filters.status == null ? filters.status : filters.status === 'open'}
          prefixText={intl.formatMessage(labelMessages.status)}
          placeholder={intl.formatMessage(labelMessages.status)}
          falseText={intl.formatMessage(globalMessages.done)}
          trueText={intl.formatMessage(globalMessages.open)}
          onChange={(value) => onChange(filterKey, value === undefined ? null : value === true ? 'open' : 'done')}
          {...props}
        />
      )
    case Filter.AS_OF:
      return <DateTimePicker key={filterKey} value={localFilters[Filter.AS_OF]} onChange={(v) => onChange(filterKey, v)} placeholder={intl.formatMessage(labelMessages[Filter.AS_OF])} prefixPlaceholder nullable {...props} />
    case Filter.ITEM_TAG:
      return <TagPicker key='t' gray={inModal} placeholder={intl.formatMessage(labelMessages[Filter.ITEM_TAG])} allowClear tagType='item' value={filters[filterKey]} onChange={(itemTag) => onChange(filterKey, itemTag)} onValidationChange={(prop) => onValidationChange(filterKey, prop)} />
  }
}

export const pageSettingsToPageFilters = (filters, mapping = {}) => {
  return {
    ...omit(filters, Object.values(mapping)),
    ...Object.keys(mapping).reduce((acc, key) => {
      acc[key] = filters[mapping[key]]
      return acc
    }, {})
  }
}

export const PageFilters = memo(({ settings, referenceSettings, localSettings, onChange, inModal, onReset, allowReset, filterComponentKeys, v1, disabled, definitionId, onValidationChange }) => {
  const intl = useIntl()
  const [cachedFilters, setCachedFilters] = useState(settings)
  const { pathname } = useLocation()

  useEffect(() => {
    if (!isEqual(cachedFilters, settings)) {
      setCachedFilters(settings)
    }
  }, [settings])

  // please don't fire me for conditional hooks, but useAvailableFilters makes too much shit we don't need in the Chart Configurator
  // filterComponentKeys will always be set in the configurator and never in the Actions component. As long as this doesn't change, we are fine.
  let filterComponents
  if (filterComponentKeys) {
    filterComponents = useMemo(() => filterComponentKeys.map(key => getFilterProps(key, pathname, intl, getRangePickerPresets(pathname), false, true)), [filterComponentKeys])
  } else {
    filterComponents = useAvailableFilters()
  }

  const mergedReference = useMemo(() => {
    const f = {
      [Filter.LOCATION]: [],
      ...mergeFilters(cachedFilters, referenceSettings)
    }
    return f
  }, [cachedFilters, referenceSettings])

  // if the filters are disabled, we only want to show the components which are actually have values
  if (disabled) {
    filterComponents = filterComponents.filter(c => {
      let k = typeof (c) === 'string' ? c : c.key
      k = k === Filter.LOCATIONS ? Filter.LOCATION : k
      if (k === Filter.DATE_RANGE) {
        return cachedFilters[k] && ((Array.isArray(cachedFilters[k]) && cachedFilters[k].value?.length > 0) || Object.keys(cachedFilters[k]).length > 0)
      } else {
        return !isNullOrEmptyArray(cachedFilters[k])
      }
    })
  }

  const components = useMemo(() => {
    return filterComponents.map((filter) => renderFilter(filter, pathname, cachedFilters, mergedReference, localSettings, intl, onChange, inModal, v1, disabled, definitionId, onValidationChange))
  }, [filterComponents, pathname, cachedFilters, localSettings, intl, onChange, mergedReference])

  return (
    <>
      {components}
      {allowReset && (
        <Button type='neutral' pure size='s' onClick={onReset}>
          <FormattedMessage {...globalMessages.resetAllFilters} />
        </Button>
      )}
    </>
  )
})
