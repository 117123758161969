import { omit } from 'lodash'
import { baseNumberFormatter, currencyFormatter, dateFormatter, gramFormatter, kgFormatter, numberFormatter, liquidFormatter, dateTimeFormatter, deviationFormatter, percentFormatter, timeRangeFormatter, daysFormatter } from './formatter'
import { DateWeatherRenderer } from './Renderer/DateWeatherRenderer'
import { BASE_VALUE_FORMAT, CURRENCY_VALUE_FORMAT, LIQUID_VALUE_FORMAT, PERCENT_VALUE_FORMAT, SMALL_WEIGHT_VALUE_FORMAT, WEIGHT_VALUE_FORMAT } from 'constants/index'
import NumberFormatEditor from './Editors/NumberFormatEditor'
import classNames from 'classnames'
import { TagsRenderer } from './Renderer/TagsRenderer'
import { BooleanRenderer } from './Renderer/BooleanRenderer'
import { ButtonsRenderer } from './Renderer/ButtonsRenderer'
import { MeasurementRenderer } from './Renderer/MeasurementRenderer'
import { ConfidenceRenderer } from './Renderer/ConfidenceRenderer'
import { DifferenceRenderer } from './Renderer/DifferenceRenderer'
import { TextWithTooltipRenderer } from './Renderer/TextWithTooltipRenderer'
import { ForecastRenderer } from './Renderer/ForecastRenderer'
import { CheckboxRenderer } from './Renderer/CheckboxRenderer'
import { DateRangeRenderer } from './Renderer/DateRangeRenderer'
import { NumberWithDifferenceRenderer } from './Renderer/NumberWithDifferenceRenderer'
import { DatastatusRenderer } from './Renderer/DataStatusRenderer'
import { AddressRenderer } from './Renderer/AddressRenderer'
import TagsEditor from './Editors/TagsEditor'
import { DownloadFileRenderer } from './Renderer/DownloadFileRenderer'
import { LinkRenderer } from './Renderer/LinkRenderer'
import { WeekdaysRenderer } from './Renderer/WeekdaysRenderer'
import { NumberWithComparisonValueRenderer } from './Renderer/NumberWithComparisonValueRenderer'

const withCompareDataMinWidth = 200

// We want an easy to use API for developers, so we define a single type for a column
// we pass to the grid. This function will convert that column into the ag-grid column definition
// using the right class, value formatters or renderers
export const getColumnDefinitionByColumn = (column, { intl, tagCollection, density, demo, hasCompareData }) => {
  let agGridProps
  switch (column.type) {
    case 'text':
    default:
      agGridProps = {
        ...(column.editable ? { cellClass: 'editable' } : undefined),
        ...(column.disguise && demo ? { valueFormatter: ({ value }) => demo(value) } : undefined)
      }
      break
    case 'textWithTooltip':
      agGridProps = {
        ...(column.editable ? { cellClass: 'editable' } : undefined),
        cellRenderer: TextWithTooltipRenderer
      }
      break
    case 'link':
      agGridProps = {
        cellRenderer: LinkRenderer
      }
      break
    case 'address':
      agGridProps = {
        cellRenderer: AddressRenderer
      }
      break
    case 'textLocalized':
      agGridProps = {
        valueFormatter: ({ value, colDef }) => {
          const locStr = colDef.localeDict ? colDef.localeDict[value] : null
          return locStr ? intl.formatMessage(locStr) : value
        }
      }
      break
    case 'number':
      agGridProps = {
        cellClass: typeof (column.cellClass) === 'function' ? column.cellClass : classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: numberFormatter,
        ...(column.withDifference || column.isLocked ? { cellRenderer: NumberWithDifferenceRenderer } : undefined),
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                format: { ...BASE_VALUE_FORMAT, defaultValue: '' },
                suffix: column.editorSuffix
              },
              density
            }
          : undefined)
      }
      break
    case 'integer':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: baseNumberFormatter,
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              editable: true,
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                format: { ...BASE_VALUE_FORMAT, defaultValue: '' }
              },
              density
            }
          : undefined)
      }
      break
    case 'currency':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: currencyFormatter,
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                format: { ...CURRENCY_VALUE_FORMAT, defaultValue: '' }
              },
              density
            }
          : undefined)
      }
      break
    case 'percent':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: percentFormatter,
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                format: { ...PERCENT_VALUE_FORMAT, defaultValue: '' }
              },
              density
            }
          : undefined)
      }
      break
    case 'kg':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: kgFormatter,
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                format: { ...WEIGHT_VALUE_FORMAT, defaultValue: '' }
              },
              density
            }
          : undefined)
      }
      break
    case 'gram':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: gramFormatter,
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                format: { ...SMALL_WEIGHT_VALUE_FORMAT, defaultValue: '' }
              },
              density
            }
          : undefined)
      }
      break
    case 'liquid':
      agGridProps = {
        cellClass: classNames('numeric', column.editable === true ? 'editable' : null),
        valueFormatter: liquidFormatter,
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                format: { ...LIQUID_VALUE_FORMAT, defaultValue: '' }
              },
              density
            }
          : undefined)
      }
      break
    case 'date':
      agGridProps = {
        valueFormatter: dateFormatter,
        intl
      }
      break
    case 'datetime':
      agGridProps = {
        valueFormatter: dateTimeFormatter,
        intl
      }
      break
    case 'dateweather':
      agGridProps = {
        cellRenderer: DateWeatherRenderer
      }
      break
    case 'daterange':
      agGridProps = {
        cellRenderer: DateRangeRenderer
      }
      break
    case 'timerange':
      agGridProps = {
        valueFormatter: timeRangeFormatter
      }
      break
    case 'weekdays':
      agGridProps = {
        cellRenderer: WeekdaysRenderer
      }
      break
    case 'days':
      agGridProps = {
        valueFormatter: daysFormatter,
        intl
      }
      break
    case 'datastatus':
      agGridProps = {
        cellRenderer: DatastatusRenderer
      }
      break
    case 'measurement':
      agGridProps = {
        cellRenderer: MeasurementRenderer,
        valueFormatter: kgFormatter,
        ...(column.withComparison ? { cellRenderer: NumberWithComparisonValueRenderer, ...(hasCompareData ? { minWidth: withCompareDataMinWidth } : undefined) } : undefined),
        ...(column.editable
          ? {
              cellEditor: NumberFormatEditor,
              cellEditorParams: {
                format: { ...WEIGHT_VALUE_FORMAT, defaultValue: '' }
              },
              density
            }
          : undefined)
      }
      break
    case 'forecast':
      agGridProps = {
        cellRenderer: ForecastRenderer,
        valueFormatter: deviationFormatter
      }
      break
    case 'deviation':
      agGridProps = {
        valueFormatter: deviationFormatter
      }
      break
    case 'tags':
      agGridProps = {
        cellRenderer: TagsRenderer,
        tagCollection,
        ...(column.editable
          ? {
              cellClass: 'editable',
              cellEditor: TagsEditor,
              cellEditorParams: {
                tagType: column.tagType
              },
              density,
              minWidth: 256 // To fit the TagPicker
            }
          : undefined)
      }
      break
    case 'boolean':
      agGridProps = {
        cellRenderer: BooleanRenderer,
        intl
      }
      break
    case 'buttons':
      agGridProps = {
        cellRenderer: ButtonsRenderer,
        density
      }
      break
    case 'confidence':
      agGridProps = {
        cellRenderer: ConfidenceRenderer
      }
      break
    case 'difference':
      agGridProps = {
        cellRenderer: DifferenceRenderer
      }
      break
    case 'checkbox':
      agGridProps = {
        cellRenderer: CheckboxRenderer,
        cellEditor: null,
        intl
      }
      break
    case 'downloadFile':
      agGridProps = {
        cellRenderer: DownloadFileRenderer,
        intl
      }
      break
    case 'component':
      agGridProps = {
        cellRenderer: column.renderer,
        intl
      }
      break
    case 'customformatter':
      agGridProps = {}
      break
  }

  const classes = column.classesFunc ? (props) => classNames(column.classesFunc(props), agGridProps.cellClass) : agGridProps.cellClass

  return {
    ...omit(column, ['type']),
    colType: column.type,
    ...agGridProps,
    cellClass: classes
  }
}

export const getOrderingFromColState = (currentColState, defaultOrdering, sortKeys = {}, asObj = false) => {
  const firstColumnWithOrder = currentColState ? currentColState.find((c) => c.sortIndex != null) : null

  // default sort
  if (!firstColumnWithOrder) return asObj ? defaultOrdering : defaultOrdering.map(o => `${o.desc ? '-' : ''}${o.key}`).join(',')
  const sortKey = sortKeys[firstColumnWithOrder.colId] || firstColumnWithOrder.colId
  const sortingKeys = sortKey === 'address' ? ['city', 'address_one'] : [sortKey]

  // remove the first column from the default ordering
  const defaultOrderingFiltered = defaultOrdering.filter(o => !sortingKeys.includes(o.key))

  if (asObj) {
    return [
      ...sortingKeys.map(k => ({ key: k, desc: firstColumnWithOrder.sort === 'desc' })),
      ...defaultOrderingFiltered
    ]
  }

  return [
    ...sortingKeys.map(k => firstColumnWithOrder.sort === 'asc' ? k : `-${k}`),
    ...defaultOrderingFiltered.map(o => `${o.desc ? '-' : ''}${o.key}`)
  ].join(',')
}
