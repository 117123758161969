import { getDomain, getSimpleChartDataLegacy, shouldFilterWeekdays } from 'utils'
import { useChartGrouping, useDashboardRefreshInterval, useDataQuery } from '../'
import { useEffect, useMemo } from 'react'

import message from 'services/message'
import notifyMessages from 'components/notifyMessages'
import { useInterval } from 'ahooks'
import { useIntl } from 'react-intl'
import { useUI } from 'services/UIProvider'
import { usePageSettings } from 'hooks/usePageSettings'
import { useChartQueryDebugger } from 'hooks/useChartQueryDebugger'
import { useChartQueryResultSetter } from 'hooks/useChartQueryResultSetter'
import { useChartExport } from 'hooks/useChartExport'
import { Filter } from 'hooks/useAvailableFilters'

export const useSimpleChartData = (identifier, dataOptions, totalValueOptions, prevValueOptions, throwOnError, options) => {
  const cOpts = {
    filterOutNulls: false,
    filterOutZeros: false,
    processTotal: null,
    processPrev: null,
    processData: null,
    additionalExportColumns: null,
    ...options
  }
  const intl = useIntl()
  const opts = { staleTime: 300000 }
  const { onDebugClick, setResult, setResponse, result } = useChartQueryDebugger(identifier, { dataOptions, totalValueOptions, prevValueOptions })
  const { data, isFetching: dataIsFetching, refetch: dataRefetch, error: dataError } = useDataQuery(dataOptions, true, null, opts)
  const { data: totalValue, isFetching: totalValueIsFetching, refetch: totalRefetch, error: totalError } = useDataQuery(totalValueOptions, true, null, opts)
  const { data: prevValue, isFetching: prevValueIsFetching, refetch: prevRefetch, error: prevError } = useDataQuery(prevValueOptions, true, null, opts)
  const { settings } = usePageSettings()
  const { resetChartErrorKey } = useUI()

  const interval = useDashboardRefreshInterval()
  useInterval(() => {
    dataRefetch()
    totalRefetch()
    prevRefetch()
    window.setTimeout(() => resetChartErrorKey(), 500)
  }, interval)

  const rTotalValue = useMemo(() => {
    if (totalValue != null && cOpts.processTotal) return cOpts.processTotal(totalValue)
    return totalValue
  }, [totalValue])

  const rPrevValue = useMemo(() => {
    if (prevValue != null && cOpts.processPrev) return cOpts.processPrev(prevValue)
    return prevValue
  }, [prevValue])

  const difference = (rTotalValue != null && rPrevValue != null) ? rTotalValue - rPrevValue : 0
  const delta = Math.abs(difference)

  const loading = dataIsFetching || totalValueIsFetching || prevValueIsFetching

  const groupBy = useChartGrouping(data)
  const { exportData } = useChartExport(identifier, result, { groupBy, additionalExportColumns: cOpts.additionalExportColumns })

  const chartData = useMemo(() => {
    const res = getSimpleChartDataLegacy(data || {}, groupBy, settings[Filter.DATE_RANGE]?.value, shouldFilterWeekdays(settings.weekdays) ? settings.weekdays : null, cOpts.filterOutNulls, cOpts.filterOutZeros)
    return {
      ...res,
      data: (cOpts.processData) ? cOpts.processData(res.data) : res.data
    }
  }, [data, groupBy])
  const domain = getDomain(chartData.data)

  useChartQueryResultSetter(setResponse, { data, totalValue, prevValue })
  useChartQueryResultSetter(setResult, { chartData: chartData.data, rTotalValue, rPrevValue })

  const errors = loading
    ? { data: null, totalValue: null, prevValue: null }
    : {
        data: dataError,
        totalValue: totalError,
        prevValue: prevError
      }

  useEffect(() => {
    if (!loading) {
      const eMessages = []

      if (dataError) {
        eMessages.push(intl.formatMessage(notifyMessages.chartError, { identifier: identifier, type: 'data', message: typeof (dataError) === 'object' ? dataError.message || dataError.error : dataError }))
      }
      if (totalError) {
        eMessages.push(intl.formatMessage(notifyMessages.chartError, { identifier: identifier, type: 'totalValue', message: typeof (totalError) === 'object' ? totalError.message || totalError.error : totalError }))
      }
      if (prevError) {
        eMessages.push(intl.formatMessage(notifyMessages.chartError, { identifier: identifier, type: 'prevValue', message: typeof (prevError) === 'object' ? prevError.message || prevError.error : prevError }))
      }

      if (eMessages.length > 0) {
        if (throwOnError) {
          throw new Error(eMessages.join(','))
        } else {
          message.error(eMessages, 15)
          eMessages.forEach(m => console.error(m))
        }
      }
    }
  }, [loading, dataError, totalError, prevError])

  return {
    difference,
    delta,
    loading,
    domain,

    hasDataWithoutTimestamp: chartData.hasDataWithoutTimestamp || false,
    chartData: chartData.data,
    totalValue: rTotalValue || 0,
    prevValue: rPrevValue,
    realGroupBy: groupBy,
    errors,
    onDebugClick,
    exportData
  }
}
