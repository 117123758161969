import { useMemo } from 'react'

const useIsMacOS = () => {
  return useMemo(() => {
    const userAgent = navigator.userAgent || ''
    return /Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)
  }, [])
}

export default useIsMacOS
