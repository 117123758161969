import { lazy } from 'react'
import chartTitleMessages from './titleMessages'
import titleMessages from 'components/titleMessages'
import labelMessages from 'components/labelMessages'

const AvgTransactionRevenue = lazy(() => import('./AvgTransactionRevenue'))
const CompositionChart = lazy(() => import('./Composition'))
const FoodwasteChart = lazy(() => import('./Foodwaste'))
const FoodwasteAmountPerSale = lazy(() => import('./FoodwasteAmountPerSale'))
const FoodwasteCo2Chart = lazy(() => import('./FoodwasteCo2'))
const FoodwasteCompositionChart = lazy(() => import('./FoodwasteComposition'))
const FoodwasteDetailChart = lazy(() => import('./FoodwasteDetail'))
const FoodwasteLocationComparisonChart = lazy(() => import('./FoodwasteLocationComparison'))
const FoodwasteSalesCostsChart = lazy(() => import('./FoodwasteSalesCosts'))
const FoodwasteWaterChart = lazy(() => import('./FoodwasteWater'))
const ForecastAccuracyChart = lazy(() => import('./ForecastAccuracy'))
const ForecastChart = lazy(() => import('./Forecast'))
const PlannedSoldDiffChart = lazy(() => import('./PlannedSoldDiff'))
const RelativeCogsChart = lazy(() => import('./RelativeCogs'))
const RevenueChart = lazy(() => import('./Revenue'))
const SalesChart = lazy(() => import('./Sales'))
const SalesDetailChart = lazy(() => import('./SalesDetail'))
const SalesLocationComparisonChart = lazy(() => import('./SalesLocationComparison'))
const TodoDoneTasksChart = lazy(() => import('./TodoDoneTasks'))
const TodoItemComparisonChart = lazy(() => import('./TodoItemComparison'))
const TodoLocationComparisonChart = lazy(() => import('./TodoLocationComparison'))
const TopsFlopsChart = lazy(() => import('./TopsFlops'))
const TotalCogsChart = lazy(() => import('./TotalCogs'))
const Transactions = lazy(() => import('./TransactionsSum'))
const OrderedAmountsChart = lazy(() => import('./OrderedAmounts'))
const RequestedAmountsChart = lazy(() => import('./RequestedAmounts'))
const FulfilledAmountsChart = lazy(() => import('./FulfilledAmounts'))
const UnsoldAmountsChart = lazy(() => import('./UnsoldAmounts'))
const ReturnRateChart = lazy(() => import('./ReturnRate'))
const DepreciatedAmountsChart = lazy(() => import('./DepreciatedAmounts'))
const OrdersOverviewChart = lazy(() => import('./OrdersOverview'))

export const REVENUE_CHART = 'revenue'
export const REL_COGS_CHART = 'rel_cogs'
export const TOTAL_COGS_CHART = 'total_cogs'
export const NUM_SOLD_CHART = 'num_sold'
export const LOCATIONS_COMPARISON_CHART = 'locations_comparison'
export const COMPOSITION_CHART = 'composition'
export const DETAIL_VIEW_CHART = 'detail_view'
export const DIFFERENCE_FROM_CHART = 'difference_from'
export const FORECAST_CHART = 'forecast'
export const FORECAST_ACCURACY_CHART = 'forecast_difference'
export const ARTICLE_COMPARISON_CHART = 'productions_comparison'
export const TRANSACTIONS_CHART = 'transactions'
export const AVG_TRANSACTION_REVENUE_CHART = 'avg_transaction_revenue'

export const ORDERED_AMOUNTS_CHART = 'ordered_amounts'
export const REQUESTED_AMOUNTS_CHART = 'requested_amounts'
export const FULFILLED_AMOUNTS_CHART = 'fulfilled_amounts'
export const UNSOLD_AMOUNTS_CHART = 'unsold_amounts'
export const NUM_VANISHED_CHART = 'num_vanished'
export const ORDERS_OVERVIEW_CHART = 'orders_overview'
export const RETURN_RATE_CHART = 'return_rate'

export const FOODWASTE_CHART = 'foodwaste'
export const FOODWASTE_COMPOSITION_CHART = 'foodwaste_composition'
export const FOODWASTE_DETAIL_VIEW_CHART = 'foodwaste_detail_view'
export const FOODWASTE_LOCATIONS_COMPARISON_CHART =
  'foodwaste_locations_comparison'
export const FOODWASTE_SALES_COSTS_CHART = 'foodwaste_sales_costs'
export const FOODWASTE_CO2_CHART = 'foodwaste_co2'
export const FOODWASTE_WATER_CHART = 'foodwaste_water'
export const FOODWASTE_AMOUNT_PER_SALE = 'foodwaste_amount_per_sale'

export const TODO_LOCATION_COMPARISON_CHART = 'todo_status_location'
export const TODO_ITEM_COMPARISON_CHART = 'todo_item_comparison'
export const TODO_DONE_TASKS_CHART = 'todo_done_tasks'

export const CHARTS = {
  [REVENUE_CHART]: RevenueChart,
  [REL_COGS_CHART]: RelativeCogsChart,
  [TOTAL_COGS_CHART]: TotalCogsChart,
  [NUM_SOLD_CHART]: SalesChart,
  [LOCATIONS_COMPARISON_CHART]: SalesLocationComparisonChart,
  [COMPOSITION_CHART]: CompositionChart,
  [DETAIL_VIEW_CHART]: SalesDetailChart,
  [DIFFERENCE_FROM_CHART]: PlannedSoldDiffChart,
  [FORECAST_CHART]: ForecastChart,
  [FORECAST_ACCURACY_CHART]: ForecastAccuracyChart,
  [ARTICLE_COMPARISON_CHART]: TopsFlopsChart,
  [TRANSACTIONS_CHART]: Transactions,
  [AVG_TRANSACTION_REVENUE_CHART]: AvgTransactionRevenue,

  [ORDERS_OVERVIEW_CHART]: OrdersOverviewChart,
  [ORDERED_AMOUNTS_CHART]: OrderedAmountsChart,
  [REQUESTED_AMOUNTS_CHART]: RequestedAmountsChart,
  [FULFILLED_AMOUNTS_CHART]: FulfilledAmountsChart,
  [UNSOLD_AMOUNTS_CHART]: UnsoldAmountsChart,
  [NUM_VANISHED_CHART]: DepreciatedAmountsChart,
  [RETURN_RATE_CHART]: ReturnRateChart,

  [FOODWASTE_CHART]: FoodwasteChart,
  [FOODWASTE_COMPOSITION_CHART]: FoodwasteCompositionChart,
  [FOODWASTE_DETAIL_VIEW_CHART]: FoodwasteDetailChart,
  [FOODWASTE_AMOUNT_PER_SALE]: FoodwasteAmountPerSale,
  [FOODWASTE_LOCATIONS_COMPARISON_CHART]: FoodwasteLocationComparisonChart,
  [FOODWASTE_SALES_COSTS_CHART]: FoodwasteSalesCostsChart,
  [FOODWASTE_CO2_CHART]: FoodwasteCo2Chart,
  [FOODWASTE_WATER_CHART]: FoodwasteWaterChart,

  [TODO_LOCATION_COMPARISON_CHART]: TodoLocationComparisonChart,
  [TODO_ITEM_COMPARISON_CHART]: TodoItemComparisonChart,
  [TODO_DONE_TASKS_CHART]: TodoDoneTasksChart
}

export const CHART_TITLES = {
  [REVENUE_CHART]: labelMessages.revenue,
  [REL_COGS_CHART]: chartTitleMessages.relativeCogs,
  [TOTAL_COGS_CHART]: chartTitleMessages.totalCogs,
  [NUM_SOLD_CHART]: labelMessages.soldNo,
  [LOCATIONS_COMPARISON_CHART]: titleMessages.locationComparison,
  [COMPOSITION_CHART]: chartTitleMessages.composition,
  [DETAIL_VIEW_CHART]: chartTitleMessages.detailView,
  [DIFFERENCE_FROM_CHART]: chartTitleMessages.differenceFrom,
  [FORECAST_CHART]: titleMessages.forecast,
  [FORECAST_ACCURACY_CHART]: chartTitleMessages.forecastDifference,
  [ARTICLE_COMPARISON_CHART]: titleMessages.itemComparison,
  [TRANSACTIONS_CHART]: labelMessages.transactionCount,
  [AVG_TRANSACTION_REVENUE_CHART]: chartTitleMessages.avgTransactionRevenue,

  [ORDERS_OVERVIEW_CHART]: labelMessages.ordersOverview,
  [ORDERED_AMOUNTS_CHART]: labelMessages.orderedAmount,
  [REQUESTED_AMOUNTS_CHART]: labelMessages.requestedAmount,
  [FULFILLED_AMOUNTS_CHART]: labelMessages.fullfilledAmount,
  [UNSOLD_AMOUNTS_CHART]: labelMessages.unsoldAmount,
  [NUM_VANISHED_CHART]: labelMessages.numVanished,
  [RETURN_RATE_CHART]: labelMessages.returnRate,

  [FOODWASTE_CHART]: titleMessages.foodwaste,
  [FOODWASTE_COMPOSITION_CHART]: chartTitleMessages.composition,
  [FOODWASTE_DETAIL_VIEW_CHART]: chartTitleMessages.detailView,
  [FOODWASTE_AMOUNT_PER_SALE]: labelMessages.amountPerSale,
  [FOODWASTE_LOCATIONS_COMPARISON_CHART]: titleMessages.locationComparison,
  [FOODWASTE_SALES_COSTS_CHART]: chartTitleMessages.foodwasteSalesCosts,
  [FOODWASTE_CO2_CHART]: labelMessages.co2,
  [FOODWASTE_WATER_CHART]: labelMessages.water,

  [TODO_LOCATION_COMPARISON_CHART]: titleMessages.locationComparison,
  [TODO_ITEM_COMPARISON_CHART]: titleMessages.itemComparison,
  [TODO_DONE_TASKS_CHART]: chartTitleMessages.todoDoneTasks
}
