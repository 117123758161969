import classNames from 'classnames'
import { fromDate } from '@internationalized/date'
import { useCalendarState } from 'react-stately'
import { useRef } from 'react'
import { useCalendar } from 'react-aria'
import { Button } from 'components/Button'
import s from './Calendar.module.scss'
import { formatLocalized } from 'utils/datetime'
import { useIntl } from 'react-intl'
import Heading from 'components/Heading'
import { CalendarGrid } from './Partials/CalendarGrid'
import { createCalendar } from './utils'

// Also see https://codesandbox.io/p/sandbox/objective-shape-8r4utm?file=%2Fsrc%2FCalendar.js for examples

/**
 * This component renders a simple calendar to select a date
 */
export const Calendar = ({ className, value, onChange, maxValue, minValue, ...props }) => {
  const intl = useIntl()
  const internalValue = value ? fromDate(typeof value === 'string' ? new Date(value) : value) : undefined

  const onDateChange = (date) => {
    if (!onChange) return
    onChange(date.toDate())
  }

  const p = {
    ...props,
    maxValue: maxValue ? fromDate(maxValue) : undefined,
    minValue: minValue ? fromDate(minValue) : undefined,
    value: internalValue,
    onChange: onDateChange
  }

  const state = useCalendarState({
    ...p,
    // this fucking bullshit is completly ignoring our given locale, but it crashes if I don't pass it. 🤦
    locale: intl.locale.substring(0, 2),
    createCalendar
  })

  const ref = useRef(null)
  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(p, state, ref)

  return (
    <div {...calendarProps} ref={ref} className={classNames(s.root, className)}>
      <div className='flex w-full justify-between items-center mb-4'>
        <Button disabled={prevButtonProps.isDisabled} onPointerDown={prevButtonProps.onPress} type='secondary' size='s' icon='ChevronLeft' data-action='prevMonth' />
        <Heading type='h5' data-cy='month-header'>{formatLocalized(state.visibleRange.start.toDate(), 'MMMM yyyy', intl)}</Heading>
        <Button disabled={nextButtonProps.isDisabled} onPointerDown={nextButtonProps.onPress} type='secondary' size='s' icon='ChevronRight' data-action='nextMonth' />
      </div>
      <CalendarGrid state={state} />
    </div>
  )
}
