import globalMessages from 'components/globalMessages'
import labelMessages from 'components/labelMessages'
import titleMessages from 'components/titleMessages'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import messages from './messages'

export const useAgGridLocale = () => {
  const intl = useIntl()

  return useMemo(() => ({
    searchOoo: `${intl.formatMessage(globalMessages.search)}...`,

    // Other
    loadingOoo: intl.formatMessage(messages.loadingOoo),
    loadingError: intl.formatMessage(globalMessages.defaultError),
    noRowsToShow: intl.formatMessage(globalMessages.noData),

    // Menu
    pinColumn: intl.formatMessage(messages.pinColumn),
    pinLeft: intl.formatMessage(messages.pinLeft),
    pinRight: intl.formatMessage(messages.pinRight),
    noPin: intl.formatMessage(messages.noPin),
    autosizeThiscolumn: intl.formatMessage(messages.autosizeThiscolumn), // AG Grid 31
    autosizeThisColumn: intl.formatMessage(messages.autosizeThiscolumn), // AG Grid 32
    autosizeAllColumns: intl.formatMessage(messages.autosizeAllColumns),
    resetColumns: intl.formatMessage(messages.resetColumns),

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: intl.formatMessage(messages.ariaLabelColumnMenu),
    ariaLabelCellEditor: intl.formatMessage(messages.ariaLabelCellEditor),
    ariaLabelDialog: intl.formatMessage(messages.ariaLabelDialog),
    ariaLabelSelectField: intl.formatMessage(messages.ariaLabelSelectField),
    ariaLabelTooltip: intl.formatMessage(messages.ariaLabelTooltip),
    ariaLabelContextMenu: intl.formatMessage(messages.ariaLabelContextMenu),
    ariaLabelSubMenu: intl.formatMessage(messages.ariaLabelSubMenu),

    columns: intl.formatMessage(labelMessages.columns),
    view: intl.formatMessage(titleMessages.view),
    export: intl.formatMessage(titleMessages.export),

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: '.',
    decimalSeparator: ','
  }), [])
}
