import { AgGridReact } from 'ag-grid-react'
import { useRef, useMemo, useEffect, useState, useCallback, memo } from 'react'
import { useAgGridLocale } from './useAGGridLocale'
import { isEqual, orderBy, omit, find } from 'lodash'
import { Pagination } from './Pagination'
import { ViewToolPanel } from './ToolPanels/ViewToolPanel'
import classNames from 'classnames'
import { getPatchValues } from 'utils'
import { useIntl } from 'react-intl'
import { usePageSettings, useAdminData, useDemo } from 'hooks'
import { getColumnDefinitionByColumn } from './utils'
import { ColumnsToolPanel } from './ToolPanels/ColumnsToolPanel'
import { useDebounceEffect, useMount, usePrevious, useUnmount } from 'ahooks'
import { BarLoader } from 'react-spinners'
import { useCurrentColState } from './hooks/useCurrentColState'
import { useGridSelectionStore } from './useGridSelectionStore'
import { ErrorBoundary } from 'react-error-boundary'
import { ApplicationError } from 'components/ApplicationError'

const COLUMN_TYPES = {
  editableColumn: {
    editable: true,
    cellClass: 'editable'
  }
}

const DEFAULT_COLUMN_DEFINITION = {
  sortable: true,
  resizable: true,
  menuTabs: ['generalMenuTab'],
  mainMenuItems: [
    'pinSubMenu',
    'separator',
    'autoSizeThis'
  ]
}

const TOOLPANEL_DEFINITIONS = [
  {
    id: 'view',
    labelKey: 'view',
    iconKey: 'smallLeft',
    toolPanel: ViewToolPanel,
    toolPanelParams: {},
    width: 350
  },
  {
    id: 'columns',
    labelKey: 'columns',
    iconKey: 'smallLeft',
    toolPanel: ColumnsToolPanel,
    toolPanelParams: {},
    width: 250
  }
  // {
  //   id: 'export',
  //   labelKey: 'export',
  //   iconKey: 'smallLeft',
  //   toolPanel: ExportToolPanel,
  //   toolPanelParams: {},
  //   width: 250
  // }
]

// This is a workaround to "disable" the automatic client-side sorting of ag-grid
const NonComparator = () => 0

const getRowIdFn = params => params.data.id.toString()

const autoGroupColumnDef = {
  headerName: '',
  cellRendererParams: {
    suppressCount: true
  },
  valueGetter: () => '',
  suppressHeaderMenuButton: true,
  suppressColumnsToolPanel: true,
  supressMovable: true,
  lockVisible: true,
  cellClass: 'actions',
  headerClass: 'actions',
  sortable: false,
  lockPinned: true,
  pinned: 'left',
  width: 44,
  maxWidth: 44,
  minWidth: 44,
  resize: false
}

const isColumSelectable = (params) => {
  const displayedColumns = params.api.getAllDisplayedColumns()
  return displayedColumns[0] === params.column
}

export const GridComponent = memo(({ columns, onCellEditRequest, onCellValueChanged, data, count, exportType, exportDefaultFormat, getRowId, noFlash, equalFn, performingRequest, treeData, onRowGroupOpened, getDataPath, noPadding, settingsKey, selectable, isRowSelectable, noSidebar, standalone, standaloneSettings, setStandaloneSettings, autoSize, autoSizeStrategy: autoSizeStrategyProps, tagType, selectionKey, hasInitialSelection, heightAsRows }) => {
  if (selectable) console.warn('Selectable is deprecated, use selectionKey instead')

  const intl = useIntl()
  const gridRef = useRef()
  const localeText = useAgGridLocale()
  const { settings: savedSettings, set: setSavedSettings } = usePageSettings()
  const { data: tagCollection } = useAdminData('tags', { type: tagType || 'saleslocation' }) // We currently only support one type for the whole table
  const [gridReady, setGridReady] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const { demo } = useDemo()
  const selection = useGridSelectionStore(state => state.selection[selectionKey]) || []
  const addToSelection = useGridSelectionStore(state => state.addToSelection)
  const removeFromSelection = useGridSelectionStore(state => state.removeFromSelection)
  const reset = useGridSelectionStore(state => state.reset)

  const autoSizeStrategy = autoSize
    ? {
        type: 'fitGridWidth',
        defaultMinWidth: 100,
        ...autoSizeStrategyProps
      }
    : undefined

  useEffect(() => {
    if (showTable && autoSize) {
      gridRef.current.api.sizeColumnsToFit(autoSizeStrategy)
    }
  }, [showTable, autoSize])

  // reset the selection on mount
  useMount(() => {
    if (selectionKey && !hasInitialSelection) reset(selectionKey)
  })
  useUnmount(() => {
    if (selectionKey && !hasInitialSelection) reset(selectionKey)
  })

  const columnDefinitions = useMemo(() => {
    return columns.map(c => ({
      ...getColumnDefinitionByColumn(c, { intl, tagCollection: tagCollection.items, density: savedSettings.colDensity, demo }),
      ...(selectionKey
        ? {
            checkboxSelection: isColumSelectable,
            headerCheckboxSelection: isColumSelectable
          }
        : {}),
      comparator: NonComparator
    }))
  }, [columns, tagCollection.items, intl, savedSettings.colDensity])

  const settings = standalone
    ? standaloneSettings
    : settingsKey
      ? savedSettings[settingsKey] || {}
      : savedSettings
  const set = (s) => standalone
    ? setStandaloneSettings({ ...standaloneSettings, ...s })
    : settingsKey
      ? setSavedSettings({ ...savedSettings, [settingsKey]: { ...settings, ...s } })
      : setSavedSettings(s)
  const currentColState = useCurrentColState(settings)

  const sideBar = useMemo(() => {
    if (noSidebar) return undefined
    return {
      toolPanels: TOOLPANEL_DEFINITIONS.filter(t => exportType ? true : t.id !== 'export').map(d => ({ ...d, toolPanelParams: { ...d.toolPanelParams, columnDefinitions, exportType, exportDefaultFormat, settingsKey } }))
    }
  }, [noSidebar, columnDefinitions])

  const rowHeight = useMemo(() => {
    switch (settings.colDensity) {
      case 'condensed':
        return 29
      case 'relaxed':
        return 50
      default:
        return 41
    }
  }, [settings.colDensity])

  /**
   * The following hooks are used to prevent overwriting the column state with the default
   * and to delay the grid visibility to make the UI more smooth
   */
  const onGridReady = () => setGridReady(true)
  useDebounceEffect(() => {
    if (!gridReady) return
    if (isReady) return
    setIsReady(true)
    if (currentColState) {
      gridRef.current.api.applyColumnState({
        state: currentColState,
        applyOrder: true
      })
    } else {
      // save the initial state
      handleColumnState()
    }
  }, [gridReady], { wait: 250 })

  useDebounceEffect(() => {
    if (isReady) setShowTable(true)
  }, [isReady], { wait: 250 })

  const handleColumnState = () => {
    if (!gridRef.current || !gridRef.current.api) { return }

    const colState = gridRef.current.api.getColumnState()

    // we can save directly since this will make other components update instantly and the settings provider takes care of debouncing the requests
    if (isReady && !isEqual(colState, currentColState)) {
      console.log('===> SAVE Column State', settings.colView, { previous: currentColState, current: colState })

      const newColStates = settings.colStates ? [...settings.colStates] : []
      const index = newColStates.findIndex((item) => item.id === settings.colView)
      if (index === -1) {
        newColStates.push({
          id: settings.colView,
          state: colState
        })
      } else {
        newColStates[index] = {
          ...newColStates[index],
          id: settings.colView,
          state: colState
        }
      }

      set({
        colView: settings.colView,
        colStates: newColStates
      })
    }
  }

  const onColumnResized = (props) => {
    if (props.finished) {
      handleColumnState()
    }
  }

  const pageSize = settings && settings.pagination ? settings.pagination.pageSize || 25 : 25

  const onPageSizeChange = (pageSize) => {
    if (pageSize) {
      set({ pagination: { current: 1, pageSize: pageSize } })
    }
  }

  const onPageChange = (page) => {
    set({ pagination: { current: page, pageSize: pageSize } })
  }

  // this code handles the cell flashing on data changes
  const previousData = usePrevious(data)
  const handleModelUpdated = ({ api }) => {
    if (!previousData || !data || noFlash) { return }

    const flashes = []
    api.forEachNode((rowNode) => {
      const { data: rowData } = rowNode
      const previousRowData = previousData.find(i => equalFn ? equalFn(i, rowData) : i.id === rowData.id)
      if (!previousRowData) {
        console.warn(`Could not find previous row data with id ${rowData.id} (${typeof rowData.id})`, rowData, previousData)
        return
      }
      const diff = getPatchValues(previousRowData, rowData, Object.keys(omit(rowData, ['updated_at'])))
      const changedColumns = Object.keys(diff)
      if (changedColumns.length > 0) {
        flashes.push({
          rowNode,
          columns: changedColumns
        })
      }
    })

    flashes.forEach((flash) => {
      api.flashCells({
        rowNodes: [flash.rowNode],
        columns: flash.columns
      })
    })
  }

  const onSortChanged = (event) => {
    const columnState = event.api.getColumnState()

    const sortColumns = orderBy(columnState.filter(i => i.sortIndex != null), ['sortIndex'], ['asc'])
    const sortModel = sortColumns.map(c => ({
      sort: c.sort,
      colId: c.colId
    }))
    if (sortModel.length > 0) {
      set({ sortModel })
    }
    handleColumnState()
  }

  useEffect(() => {
    if (selection && selection.length > 0 && data) {
      // preserve the previous selection
      if (gridReady) {
        gridRef.current.api.forEachNode((node) => {
          const selected = find(selection, { id: node.data.id })
          node.setSelected(selected != null)
        })
      }
    }
  }, [data, gridReady])

  const onRowSelected = useCallback((event) => {
    // we don't want to trigger this event when the data is updated
    if (event.source === 'rowDataChanged') return
    const isSelected = event.node.isSelected()

    if (isSelected) {
      addToSelection(selectionKey, event.node.data)
    } else {
      removeFromSelection(selectionKey, event.node.data)
    }
  }, [selectionKey, addToSelection, removeFromSelection])

  useEffect(() => {
    if (gridReady && selection) {
      gridRef.current.api.forEachNode((node) => {
        const selected = find(selection, { id: node.data.id })
        node.setSelected(selected != null)
      })
    }
  }, [selection, gridReady])

  const heightForRows = heightAsRows ? `${Math.max(rowHeight * (((data?.length || 0) <= pageSize ? (data?.length || 0) : pageSize) + 1), 200)}px` : undefined

  return (
    <div className='flex flex-col w-full h-full'>
      {performingRequest && (
        <div role='grid-bar-loader'>
          <BarLoader color='#113e6f' cssOverride={{ position: 'fixed', top: 0, left: 0 }} height={6} width='100%' speedMultiplier={0.5} />
        </div>
      )}
      <div className={classNames('flex flex-col w-full h-full transition ease-in-out duration-700 opacity-0', {
        'opacity-100': showTable
      })}
      >
        <div
          className={classNames('w-full grow ag-theme-dd', {
            'desktop:px-6': !noPadding,
            'ag-condensed': settings.colDensity === 'condensed',
            'ag-relaxed': settings.colDensity === 'relaxed',
            selectable: selectionKey
          })}
          style={{ height: heightForRows, maxHeight: heightForRows ? '65vh' : undefined }}
        >
          <AgGridReact
            loading={data === undefined}
            reactiveCustomComponents
            suppressRowClickSelection
            ref={gridRef}
            localeText={localeText}
            columnTypes={COLUMN_TYPES}
            columnDefs={columnDefinitions}
            defaultColDef={DEFAULT_COLUMN_DEFINITION}
            pagination
            paginationPageSize={pageSize}
            animateRows
            rowModelType='clientSide'
            rowData={data}
            suppressPaginationPanel
            enterNavigatesVerticallyAfterEdit
            suppressContextMenu
            onGridReady={onGridReady}
            onColumnResized={onColumnResized}
            onSortChanged={onSortChanged}
            onColumnMoved={handleColumnState}
            onColumnVisible={handleColumnState}
            onColumnPinned={handleColumnState}
            onModelUpdated={handleModelUpdated}
            sideBar={sideBar}
            rowHeight={rowHeight}
            suppressPropertyNamesCheck
            readOnlyEdit={onCellEditRequest != null}
            onCellEditRequest={onCellEditRequest}
            onCellValueChanged={onCellValueChanged}
            getRowId={getRowId || getRowIdFn}
            treeData={treeData}
            onRowGroupOpened={onRowGroupOpened}
            getDataPath={getDataPath}
            autoGroupColumnDef={autoGroupColumnDef}
            rowSelection={selectionKey ? 'multiple' : undefined}
            onRowSelected={onRowSelected}
            isRowSelectable={isRowSelectable}
            autoSizeStrategy={autoSizeStrategy}
          />
        </div>
        <Pagination
          className={noPadding ? undefined : 'desktop:mx-6'}
          allowedPageSizes={[25, 50]}
          pageSize={pageSize}
          rowCount={count}
          page={settings && settings.pagination ? settings.pagination.current : 1}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          gridApi={gridRef.current ? gridRef.current.api : null}
        />
      </div>
    </div>
  )
})

export const Grid = (props) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ApplicationError
          error={error}
          inChart
          context={{
            type: 'grid'
          }}
          resetErrorBoundary={resetErrorBoundary}
          resetAfterDelay
        />
      )}
    >
      <GridComponent {...props} />
    </ErrorBoundary>
  )
}

Grid.TYPE = {
  TEXT: 'text',
  TEXTWITHTOOLTIP: 'textWithTooltip',
  LINK: 'link',
  ADDRESS: 'address',
  NUMBER: 'number',
  INTEGER: 'integer',
  CURRENCY: 'currency',
  PERCENT: 'percent',
  CHECKBOX: 'checkbox',
  KG: 'kg',
  GRAM: 'gram',
  LIQUID: 'liquid',
  DATE: 'date',
  DATETIME: 'datetime',
  DATEWEATHER: 'dateweather',
  DATERANGE: 'daterange',
  TIMERANGE: 'timerange',
  WEEKDAYS: 'weekdays',
  DAYS: 'days',
  DEVIATION: 'deviation',
  TAGS: 'tags',
  BOOLEAN: 'boolean',
  BUTTONS: 'buttons',
  MEASUREMENT: 'measurement',
  FORECAST: 'forecast',
  CONFIDENCE: 'confidence',
  DIFFERENCE: 'difference',
  TEXTLOCALIZED: 'textLocalized',
  DATASTATUS: 'datastatus',
  DOWNLOADFILE: 'downloadFile',
  COMPONENT: 'component',
  CUSTOMFORMATTER: 'customformatter'
}

Grid.ACTION_COLUMN_PROPS = {
  colId: 'actions',
  suppressHeaderMenuButton: true,
  suppressColumnsToolPanel: true,
  supressMovable: true,
  lockVisible: true,
  cellClass: 'actions',
  headerClass: 'actions',
  sortable: false,
  lockPinned: true,
  pinned: 'right',
  resize: false,
  width: 60,
  maxWidth: 60,
  minWidth: 60
}

export { useGridSelectionStore }
