import { useEffect, useState } from 'react'
import { useAvailableFilters, Filter } from 'hooks/useAvailableFilters'
import { useDebounce, useLocations, useOfferingGroups } from 'hooks'
import { useFoodwasteCategories } from 'hooks/queries/useFoodwasteCategories'
import { useItemNames } from 'hooks/queries/useItemNames'
import { useItemCategory } from 'hooks/queries/useItemCategory'
import { isEqual } from 'lodash'
import { extractFilters } from '../utils'

export const useReportParams = (activeFilters) => {
  const { data: allLocations } = useLocations()
  const range = activeFilters[Filter.DATE_RANGE]?.value
  const baseEnabled = activeFilters != null && range != null && allLocations != null
  const filters = useAvailableFilters({ onlyKeys: true })

  // if we selected all locations, we don't want to pass the location filter at all
  const locationFilter = allLocations != null && Array.isArray(activeFilters.location)
    ? !(!activeFilters.location.length || activeFilters.location.length === allLocations.length)
        ? activeFilters.location
        : null
    : activeFilters.location ? [activeFilters.location] : null

  const { data: allWasteCategories } = useFoodwasteCategories({ enabled: filters.includes(Filter.FOODWASTE_CATEGORY) })
  const { data: allOg1 } = useOfferingGroups({ offeringGroupNo: 1, locations: locationFilter, range, enabled: baseEnabled && filters.includes(Filter.OFFERING_GROUP_1) })
  const { data: allOg2 } = useOfferingGroups({ offeringGroupNo: 2, locations: locationFilter, range, enabled: baseEnabled && filters.includes(Filter.OFFERING_GROUP_2) })
  const { data: allItemCategories1 } = useItemCategory({ categoryNo: 1, location: locationFilter, range, enabled: baseEnabled && filters.includes(Filter.ITEM_GROUP_1) })
  const { data: allItemCategories2 } = useItemCategory({ categoryNo: 2, location: locationFilter, range, enabled: baseEnabled && filters.includes(Filter.ITEM_GROUP_2) })
  const { data: allItemNames } = useItemNames(
    range,
    allLocations
      ? locationFilter
      : null,
    null,
    baseEnabled && filters.includes(Filter.ITEM)
  )

  const collectFilters = () => {
    const passedSettings = {
      ...activeFilters,
      ...(activeFilters.og1 && activeFilters.og1.length > 0 && allOg1 ? { og1Names: allOg1.filter(i => activeFilters.og1.includes(i.id.toString())).map(i => i.name) } : undefined),
      ...(activeFilters.og2 && activeFilters.og2.length > 0 && allOg2 ? { og2Names: allOg2.filter(i => activeFilters.og2.includes(i.id.toString())).map(i => i.name) } : undefined)
    }

    return extractFilters(passedSettings, allLocations, allOg1, allOg2, allItemCategories1, allItemCategories2, allItemNames, allWasteCategories)
  }

  const [storedFilters, setStoredFilters] = useState(collectFilters())
  // this memo only listens to activeFilters.
  // If initially on setting changes not all data were ready, we don't want to trigger a new request as soon they are ready
  // so we keep filters we would normally omit until the activeFilters change to prevent unnecessary requests
  useEffect(
    () => {
      const f = collectFilters()
      if (!storedFilters || !isEqual(f, storedFilters)) {
        setStoredFilters(f)
      }
    },
    [activeFilters]
  )

  const filterParams = useDebounce(storedFilters, { wait: 800 })
  return filterParams
}
