import { DropdownMenu } from 'components/DropdownMenu'

import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import titleMessages from 'components/titleMessages'
import { Button } from 'components/Button'
import labelMessages from 'components/labelMessages'
import globalMessages from 'components/globalMessages'
import { useGridSelectionStore } from 'components/Grid'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { useWasteMeasurements } from 'hooks/queries/useWasteMeasurements'
import { invalidateFoodwasteQueries } from './utils'
import { useQueryClient } from '@tanstack/react-query'

export const BulkActionMenu = () => {
  const intl = useIntl()
  const selection = useGridSelectionStore(state => state.selection.foodwaste) || []
  const reset = useGridSelectionStore(state => state.reset)
  const [showConfirmation, setShowConfirmation] = useState(null)
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const [queriesCompleted, setQueriesCompleted] = useState(0)

  const { remove } = useWasteMeasurements({ enabled: false })
  const handleQueryCompleted = () => setQueriesCompleted(prevQueriesCompleted => prevQueriesCompleted + 1)

  const performBulkDeletion = () => {
    const promises = selection.map(data => remove.mutate(data.id).then(handleQueryCompleted))

    Promise.all(promises).then(() => {
      reset('foodwaste')
      setShowConfirmation(false)
      setLoading(false)
      invalidateFoodwasteQueries(queryClient)
    })
  }

  const handleConfirm = () => {
    setQueriesCompleted(0)
    setLoading(true)

    if (showConfirmation === 'delete') {
      performBulkDeletion()
    }
  }

  const progress = (queriesCompleted / selection.length) * 100

  return (
    <>
      <DropdownMenu
        menuSections={[{
          key: 'view',
          menuItems: [
            {
              key: 'delete',
              label: intl.formatMessage(labelMessages.deleteMeasurements),
              icon: 'TrashCan',
              onClick: () => setShowConfirmation('delete')
            }
          ]
        }]}
        align='end'
        name='drpdwn-bulkAction'
        checkmark
        smallWidth
      >
        <Button
          icon='Edit'
          type='primary'
          name='btn-bulk-action'
          size='s'
        >
          {intl.formatMessage(titleMessages.bulkAction, { count: selection.length })}
        </Button>
      </DropdownMenu>
      <Button type='tertiary' size='s' className='ml-1' onClick={() => reset('foodwaste')}>
        <FormattedMessage {...globalMessages.resetSelection} />
      </Button>
      {showConfirmation && (
        <ConfirmationDialog
          title={intl.formatMessage(showConfirmation === 'delete' ? labelMessages.deleteMeasurements : '')}
          onCancel={() => setShowConfirmation(null)}
          visible
          onConfirm={handleConfirm}
          noUndoHint
          confirmLoading={loading}
          progress={loading ? progress : null}
        >
          {showConfirmation === 'delete'
            ? (
              <FormattedMessage
                id='foodwaste.deleteMeasurementsConfirmation'
                defaultMessage='Are you sure you want to delete {count} selected open measurements?'
                values={{ count: selection.length }}
              />
              )
            : null}
        </ConfirmationDialog>
      )}
    </>
  )
}
