import { defineMessages } from 'react-intl'

export default defineMessages({
  account: {
    id: 'title.account',
    defaultMessage: 'Account'
  },
  users: {
    id: 'title.users',
    defaultMessage: 'Users'
  },
  settings: {
    id: 'title.settings',
    defaultMessage: 'Settings'
  },
  appSettings: {
    id: 'title.appSettings',
    defaultMessage: 'App Settings'
  },
  general: {
    id: 'title.general',
    defaultMessage: 'General'
  },
  personalData: {
    id: 'title.personalData',
    defaultMessage: 'Personal data'
  },
  security: {
    id: 'title.security',
    defaultMessage: 'Security'
  },
  language: {
    id: 'title.language',
    defaultMessage: 'Language'
  },
  userSettings: {
    id: 'title.userSettings',
    defaultMessage: 'User Settings'
  },
  aboutDeliciousData: {
    id: 'title.aboutDeliciousData',
    defaultMessage: 'About Delicious Data'
  },
  subscriptions: {
    id: 'title.subscriptions',
    defaultMessage: 'Subscriptions'
  },
  forecast: {
    id: 'title.forecast',
    defaultMessage: 'Forecast'
  },
  foodwaste: {
    id: 'title.foodwaste',
    defaultMessage: 'Food Waste'
  },
  orders: {
    id: 'title.orders',
    defaultMessage: 'Orders'
  },
  locationComparison: {
    id: 'title.locationComparison',
    defaultMessage: 'Location Comparison'
  },
  itemComparison: {
    id: 'title.itemComparison',
    defaultMessage: 'Item Comparison'
  },
  customers: {
    id: 'title.customers',
    defaultMessage: 'Customers'
  },
  operator: {
    id: 'title.operator',
    defaultMessage: 'Operator'
  },
  dashboard: {
    id: 'title.dashboard',
    defaultMessage: 'Analysis'
  },
  helpCenter: {
    id: 'title.helpCenter',
    defaultMessage: 'Help Center'
  },
  todo: {
    id: 'title.todo',
    defaultMessage: 'Daily Planner'
  },
  development: {
    id: 'title.development',
    defaultMessage: 'Development'
  },
  admin: {
    id: 'title.admin',
    defaultMessage: 'Admin'
  },
  import: {
    id: 'title.import',
    defaultMessage: 'Import'
  },
  export: {
    id: 'title.export',
    defaultMessage: 'Export'
  },
  openMeasurements: {
    id: 'title.openMeasurements',
    defaultMessage: 'Open Measurements'
  },
  columnSelection: {
    id: 'title.columnSelection',
    defaultMessage: 'Column Selection'
  },
  fieldSelection: {
    id: 'title.fieldSelection',
    defaultMessage: 'Field Selection'
  },
  forecastSettings: {
    id: 'title.forecastSettings',
    defaultMessage: 'Forecast Settings'
  },
  safetyBuffer: {
    id: 'title.safetyBuffer',
    defaultMessage: 'Safety Buffer'
  },
  gridView: {
    id: 'title.gridView',
    defaultMessage: 'Grid View'
  },
  tableView: {
    id: 'title.tableView',
    defaultMessage: 'Table View'
  },
  preview: {
    id: 'title.preview',
    defaultMessage: 'Preview'
  },
  fileUpload: {
    id: 'title.fileUpload',
    defaultMessage: 'File Upload'
  },
  configureView: {
    id: 'title.configureView',
    defaultMessage: 'Configure View'
  },
  autoRefresh: {
    id: 'title.autoRefresh',
    defaultMessage: 'Auto Refresh'
  },
  reports: {
    id: 'title.reports',
    defaultMessage: 'Reports'
  },
  offering: {
    id: 'title.offering',
    defaultMessage: 'Offering'
  },
  login: {
    id: 'title.login',
    defaultMessage: 'Login'
  },
  sftp: {
    id: 'title.sftp',
    defaultMessage: 'SFTP'
  },
  plannedSoldDiff: {
    id: 'title.plannedSoldDiff',
    defaultMessage: 'Planned No. and Sold No.'
  },
  forecastSoldDiff: {
    id: 'title.forecastSoldDiff',
    defaultMessage: 'Forecast and Sold No.'
  },
  preparationInstructions: {
    id: 'title.preparationInstructions',
    defaultMessage: 'Preparation Instructions'
  },
  view: {
    id: 'title.view',
    defaultMessage: 'View'
  },
  savedSettings: {
    id: 'title.savedSettings',
    defaultMessage: 'Saved Settings'
  },
  workspace: {
    id: 'title.workspace',
    defaultMessage: 'Workspace'
  },
  info: {
    id: 'title.info',
    defaultMessage: 'Information'
  },
  providedBy: {
    id: 'title.providedBy',
    defaultMessage: 'Provided by'
  },
  backendScripts: {
    id: 'title.backendScripts',
    defaultMessage: 'Backend Scripts'
  },
  recipes: {
    id: 'title.recipes',
    defaultMessage: 'Recipes'
  },
  events: {
    id: 'title.events',
    defaultMessage: 'Events'
  },
  addOpenMeasurement: {
    id: 'title.addOpenMeasurement',
    defaultMessage: 'Add Open Measurement'
  },
  enterMeasurements: {
    id: 'title.enterMeasurements',
    defaultMessage: 'Enter measurements'
  },
  decisionLog: {
    id: 'title.decisionLog',
    defaultMessage: 'Decision Log'
  },
  bulkAction: {
    id: 'title.bulkAction',
    defaultMessage: 'Bulk Action ({count} selected)'
  },
  textReport: {
    id: 'title.textReport',
    defaultMessage: 'Text Report'
  }
})
