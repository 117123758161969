import { GenericForm } from 'components/Form'
import { Icon } from 'components/Primitives'
import titleMessages from 'components/titleMessages'
import { useModalStore } from 'hooks/store'
import { FormattedMessage, useIntl } from 'react-intl'
import s from './HelpCenter.module.scss'
import { memo } from 'react'
import { Conversation, DeliverInsights } from '@carbon/pictograms-react'

const HelpLinks = () => {
  return (
    <div className='flex space-x-8 w-full justify-center'>
      <a className={s.link} href='https://help.delicious-data.com' target='_blank' rel='noreferrer'>
        <DeliverInsights className='mb-2' />
        <FormattedMessage id='helpCenter.wiki' defaultMessage='Knowledge Base' />
      </a>
      <a className={s.link} href='https://share-eu1.hsforms.com/1JEnSVPxqQJ-e1qJk28JOtAfsv3u' target='_blank' rel='noreferrer'>
        <Conversation className='mb-2' />
        <FormattedMessage id='helpCenter.supportTicket' defaultMessage='Support Ticket' />
      </a>
    </div>
  )
}

// Using GenericForm here, because we will soon write support tickets from UI
export const HelpCenter = memo(() => {
  const intl = useIntl()
  const isOpen = useModalStore(state => state.modals.helpCenter)
  const hide = useModalStore(state => state.hide)

  const close = () => hide('helpCenter')

  const structure = [{
    fields: [
      {
        type: 'component',
        name: 'links',
        render: HelpLinks
      }
    ]
  }]

  return (
    <GenericForm
      noFooter
      asModal
      visible={isOpen}
      structure={structure}
      initialValues={{}}
      title={intl.formatMessage(titleMessages.helpCenter)}
      onCancel={close}
      onSubmit={close}
    />
  )
})
