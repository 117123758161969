import React from 'react'
import classNames from 'classnames'
import s from './LoadingScreen.module.css'
import { PulseLogo } from 'components/PulseLogo'

const LoadingScreen = ({ className }) => {
  return (
    <div className={classNames(s.container, className)}>
      <PulseLogo />
    </div>
  )
}

export default LoadingScreen
