import TagBadge from 'components/Badge/TagBadge'
import classNames from 'classnames'
import { omit } from 'lodash'
import { FormattedMessage } from 'react-intl'

export const Option = ({ selectProps, innerProps, isFocused, isSelected, data, isDisabled }) => {
  return (
    <div
      data-role='option'
      {...omit(innerProps, ['onClick'])}
      onClick={innerProps.onClick}
      className={classNames(
        innerProps.className,
        'w-full px-2 flex cursor-pointer text-base',
        {
          'bg-gray-lighter': isFocused,
          'justify-between py-2': !data.__isNew__,
          'text-gray-light': !selectProps.asTags && !isDisabled,
          'p-3 justify-start': !selectProps.asTags,
          'bg-primary-tint': isSelected,
          'text-accent-neutral-20': isDisabled,
          'text-sm text-gray-light justify-start flex-nowrap space-x-1 items-center py-2': data.__isNew__
        }
      )}
    >
      {data.__isNew__
        ? selectProps.createNewMessage
            ? selectProps.createNewMessage(data)
            : <FormattedMessage
                id='tags.createGroup'
                defaultMessage='Create new group <tag>{group}</tag>'
                values={{
                  group: JSON.stringify(data),
                  tag: chunks => {
                    const t = JSON.parse(chunks)
                    return <TagBadge noTooltip noTruncate className='mx-1' color='blue'>{t.name || t.value}</TagBadge>
                  }
                }}
              />
        : selectProps.asTags
          ? <TagBadge noTooltip noTruncate hex={data.color}>{data.label}</TagBadge>
          : <span className='block w-full text-ellipsis whitespace-nowrap overflow-hidden'>{data.label}</span>}
    </div>
  )
}
