import { CUSTOM_RANGE, DFNS_LONG_DATE_FORMAT } from 'constants/index'
import React, { useEffect, useMemo, useState } from 'react'

import DatePicker from 'antd/lib/date-picker' // We have to import it like this to make sure we have the patched version
import Presets from './Presets'
import cn from 'classnames'
import { getRangePreset } from 'utils'
import m from 'moment'
import messages from './messages'
import s from './RangePicker.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import useIsMobile from 'hooks/useIsMobile'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { parseISO, isSameDay, startOfISOWeek, endOfISOWeek } from 'date-fns'
import { formatISODate, formatLocalized } from 'utils/datetime'
import labelMessages from 'components/labelMessages'
import { Icon, Text } from 'components/Primitives'

// TODO refactor it
export default ({
  value: range,
  option: rangeOption,
  onChange,
  presets,
  weekly,
  daily,
  allowReset,
  gray,
  placeholder,
  prefixPlaceholder,
  icon,
  type
}) => {
  const intl = useIntl()
  const isMobile = useIsMobile()
  const [open, onOpenChange] = useState(false)
  const option = getRangePreset(rangeOption)
  const value = useMemo(() => {
    if (!option) {
      return []
    }
    if (rangeOption !== CUSTOM_RANGE && !!option) {
      return option.value.map((item) => formatISODate(item))
    }
    return range
  }, [range, rangeOption])

  const date = value.map((item) => parseISO(item))
  const hasValue = !!date.length

  const onDateChange = (date) => {
    // support moment objects which are returned by range picker
    const pureDate = date.map(d => d._isAMomentObject ? d.toDate() : d)
    const option = presets
      .map((key) => ({ ...getRangePreset(key), key }))
      .find(
        (option) =>
          pureDate.length &&
          isSameDay(pureDate[0], option.value[0]) &&
          isSameDay(pureDate[1], option.value[1])
      )
    onChange(
      pureDate.map((item) => formatISODate(item)),
      option ? option.key : CUSTOM_RANGE
    )
  }

  useEffect(() => {
    if (range && range.length) {
      const range = [startOfISOWeek(date[0]), endOfISOWeek(date[0])]
      const isWeeklyRange = isSameDay(date[0], range[0]) && isSameDay(date[1], range[1])
      if (weekly && !isWeeklyRange) {
        onDateChange(range)
      }
    }
  }, [weekly, range])

  const presetOptions = useMemo(() => presets.map((key) => ({ ...getRangePreset(key), key })), [presets])
  const foundPreset = useMemo(() => {
    return presetOptions.find(
      (option) =>
        date.length &&
        option.value.length &&
        isSameDay(date[0], option.value[0]) &&
        isSameDay(date[1], option.value[1])
    )
  }, [value, presetOptions])

  const renderRangeText = () => {
    if (!hasValue) {
      return placeholder || intl.formatMessage(
        weekly ? labelMessages.week : labelMessages.dateRange
      )
    }

    if (foundPreset != null && foundPreset !== CUSTOM_RANGE) { return foundPreset ? intl.formatMessage(foundPreset.label, foundPreset.params) : null }

    if (!weekly) {
      return isSameDay(date[0], date[1])
        ? formatLocalized(date[0], DFNS_LONG_DATE_FORMAT, intl)
        : date.map((item) => formatLocalized(item, DFNS_LONG_DATE_FORMAT, intl)).join(' - ')
    }

    return date
      .map((item, i) =>
        formatLocalized(item,
          i === 0
            ? `'${intl.formatMessage(messages.week)}'I' | '${DFNS_LONG_DATE_FORMAT}`
            : DFNS_LONG_DATE_FORMAT,
          intl
        )
      )
      .join(' - ')
  }

  const renderInput = () => {
    return (
      <span className={cn(s.selection, hasValue && s.hasValue)}>
        <Icon name={icon || 'Calendar'} />
        <Text bold color={hasValue ? 'blue' : 'gray'}>
          {hasValue && placeholder && prefixPlaceholder
            ? `${placeholder}: ${renderRangeText()}`
            : renderRangeText()}
        </Text>
      </span>
    )
  }

  const renderPicker = (dialogContainer) => {
    const getCalendarContainer = isMobile && dialogContainer ? () => dialogContainer : undefined
    return (
      <DatePicker.RangePicker
        size='small'
        allowClear={false}
        className={cn(s.picker, gray && s.gray, s[type])}
        dropdownClassName={cn('fixed', s.popup, { [s.weekly]: weekly, [s.mobile]: isMobile })}
        value={date.map(d => m(d))} // FIXME: as long as we are using antd 2, we have to pass a moment object
        onChange={onDateChange}
        open={isMobile ? !!(open && dialogContainer) : open}
        getCalendarContainer={getCalendarContainer}
      /* RangePicker doesn't update hover value after selecting first date */
        {...(daily
          ? {
              hoverValue: date,
              onCalendarChange: ([date]) => {
                onDateChange([
                  date,
                  date
                ])
                onOpenChange(false)
              }
            }
          : {})}
        {...(weekly
          ? {
              hoverValue: date,
              onCalendarChange: ([date]) => {
                onDateChange([
                  startOfISOWeek(date.toDate()),
                  endOfISOWeek(date.toDate())
                ])
                onOpenChange(false)
              }
            }
          : {})}
        renderInput={renderInput}
        onOpenChange={onOpenChange}
        showWeekNumber
        /* custom prop for mobile view added using patch-package */
        compact={isMobile}
        renderExtraFooter={() => (
          <Presets
            onChange={onDateChange}
            value={date}
            onOpenChange={onOpenChange}
            options={presets}
            weekly={weekly}
            selectedPreset={foundPreset}
            allowReset={allowReset}
          />
        )}
      />
    )
  }

  return (
    <>
      <style>
        {`.ant-calendar-week-number-cell:before {
          content: '${intl.formatMessage(messages.week)}';
        }`}
      </style>
      {renderPicker()}
      {isMobile &&
        (
          <MobileFriendlyModal
            visible={open}
            noPadding
            title={<FormattedMessage id='RangePicker.selectRange' defaultMessage='Select Range' />}
            onCancel={() => onOpenChange(false)}
            noFooter
            popupContainerClassName={s.mobileContainer}
          >
            {renderPicker}
          </MobileFriendlyModal>
        )}
    </>
  )
}
