import { forEach, mapValues } from 'lodash'

import customersMessages from 'routes/customers/messages'
import notifyMessages from 'components/notifyMessages'
import labelMessages from 'components/labelMessages'
import titleMessages from 'components/titleMessages'
import globalMessages from 'components/globalMessages'
import formMessages from 'components/formMessages'

export const USER = 'user'
export const ADMIN = 'admin'
export const OPERATOR = 'operator'

export const LOCALES = ['en-US', 'de-DE']
export const DEFAULT_LOCALE = 'de-DE'

export const WEEK = 604800
export const MONTH = 2592000
export const YEAR = 31536000

export const TOKEN_COOKIE = 'token'
export const TOKEN_EXPIRATION = 'token_expiration'
export const TOKEN_REFRESH_AFTER = 'token_refresh_after'
export const REFRESH_TOKEN_COOKIE = 'refresh_token'
export const CHART_HEIGHT_COOKIE = 'chart_height'
export const LOCALE_COOKIE = 'lang'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DFNS_HOUR_FORMAT = 'yyyy-MM-dd\'T\'HH'
export const DFNS_DATE_FORMAT = 'yyyy-MM-dd'
export const DFNS_WEEK_FORMAT = "RRRR-'W'II"
export const DFNS_LONG_DATE_FORMAT = 'dd. MMM yyyy'
export const WEEK_FORMAT = 'GGGG-[W]W'
export const TIME_FORMAT = 'HH:mm:ss'
export const SHORT_TIME_FORMAT = 'HH:mm'
export const DFNS_LONG_FORMAT = `${DFNS_DATE_FORMAT} ${SHORT_TIME_FORMAT}`
export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY'
export const WEEKDAY_DATE_FORMAT = 'EEEEEE, dd.MM.yyyy'
export const CHART_LONG_DATE_FORMAT = 'DD.MM.YYYY HH:mm'
export const CHART_DATE_FORMAT = 'DD.MM.YYYY'
export const LONG_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@!%*?&_~`#^\(\)+=_\-\{\}\[\]\\|;:\'\"/<>,.])[A-Za-z\d$@!%*?&_~`#^\(\)+=_\-\{\}\[\]\\|;:\'\"/<>,.]{12,256}/
export const SFTP_PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()?])[A-Za-z\d!@#$%^&*()?]/

export const DEFAULT_DELIMITER = ';'

export const REQUIRED_COLUMNS = [
  'sales_loc',
  'name',
  'menuline',
  'date',
  'num_planned',
  'num_sold'
]

export const DEFAULT_COLUMNS = [
  'date',
  'name',
  'menuline',
  'num_planned',
  'forecast',
  'forecast_limit',
  'confidence'
]

export const COLUMNS = [
  'date',
  'name',
  'description',
  'menuline',
  'component',
  'dispo_id',
  'plu',
  'num_sold',
  'num_planned',
  'num_produced',
  'prod_loc',
  'prod_loc_id',
  'sales_loc',
  'sales_loc_id',
  'price',
  'cogs',
  'revenue'
]

export const ALL_FIELDS = [
  'num_planned',
  'num_forecast',
  'num_produced',
  'forecast_limit',
  'forecast_confidence',
  'description',
  'dispo_id',
  'num_sold',
  'price',
  'cogs',
  'revenue',
  'forecast_difference'
]

export const BAKERY_FIELDS = [
  'ordered_amount',
  'requested_amount',
  'fulfilled_amount',
  'num_unsold',
  'num_vanished'
]

export const DEFAULT_FIELDS = [
  'num_planned',
  'num_forecast',
  'forecast_limit',
  'forecast_confidence'
]

export const MAX_FIELD_COUNT = 5

export const OFFERING_COLUMN_TITLES = {
  date: labelMessages.date,
  name: labelMessages.name,
  prod_loc_id: labelMessages.productionLocationId,
  menuline: labelMessages.offeringGroup1,
  component: labelMessages.offeringGroup2,
  num_planned: labelMessages.planned,
  num_produced: labelMessages.produced,
  description: labelMessages.description,
  dispo_id: labelMessages.dispositionId,
  plu: labelMessages.plu,
  num_sold: labelMessages.soldNo,
  prod_loc: labelMessages.productionLocation,
  forecast_difference: labelMessages.difference,
  forecast: titleMessages.forecast,
  num_forecast: titleMessages.forecast,
  forecast_variance: labelMessages.variance,
  confidence: labelMessages.confidence,
  forecast_confidence: labelMessages.confidence,
  forecast_limit: labelMessages.forecastLimit,
  pos_menuline: labelMessages.posOfferingGroup,
  price: labelMessages.price,
  sales_loc: labelMessages.location,
  sales_loc_id: labelMessages.salesLocationId,
  sales_location: labelMessages.salesLocationId,
  cogs: labelMessages.cogs,
  revenue: labelMessages.revenue,
  ordered_amount: labelMessages.orderedAmount,
  requested_amount: labelMessages.requestedAmount,
  fulfilled_amount: labelMessages.fullfilledAmount,
  num_unsold: labelMessages.unsoldAmount,
  num_vanished: labelMessages.numVanished
}

export const USERS_COLUMN_TITLES = {
  first_name: labelMessages.firstName,
  last_name: labelMessages.lastName,
  email: labelMessages.email,
  tags: labelMessages.userTags,
  role: labelMessages.role,
  permissions: globalMessages.accessRights,
  sales_locations: labelMessages.locations,
  comment: labelMessages.comment,
  dashboard_preview: labelMessages.dashboardPreview
}

export const LOCATIONS_COLUMN_TITLES = {
  sales_loc: labelMessages.id,
  name: labelMessages.name,
  address_one: labelMessages.address,
  tags: labelMessages.locationTags,
  forecast_enabled: titleMessages.forecast,
  todo_enabled: titleMessages.todo,
  orders_enabled: labelMessages.order
}

export const MECOS_COLUMN_TITLES = {
  menuline_name: [labelMessages.offeringGroup, { no: 1 }],
  component_name: [labelMessages.offeringGroup, { no: 2 }],
  link_to: labelMessages.linkTo,
  forecasts: titleMessages.forecast
}

export const ITEMS_COLUMN_TITLES = {
  remote_pk: labelMessages.id,
  name: labelMessages.name,
  plu: labelMessages.plu,
  description: labelMessages.description,
  tags: labelMessages.itemTags,
  category_1: [labelMessages.itemGroup, { no: 1 }],
  category_2: [labelMessages.itemGroup, { no: 2 }],
  category_3: [labelMessages.itemGroup, { no: 3 }],
  todo_tags: labelMessages.todoGroups,
  order_batch_size: labelMessages.orderBatchSize,
  sheet_size: labelMessages.sheetSize,
  longevity: labelMessages.longevity,
  todo_batch_size: labelMessages.todoBatchSize,
  todo_need_threshold: labelMessages.todoNeedThreshold,
  todo_min_size: labelMessages.todoMinSize
}

export const FOODWASTE_CONFIGS_COLUMN_TITLES = {
  category_name: labelMessages.wasteCategory,
  sales_location_name: labelMessages.location,
  user_names: labelMessages.responsibles,
  all_measurements: labelMessages.previousMeasurements,
  open_measurements: titleMessages.openMeasurements,
  benchmark: labelMessages.benchmark,
  benchmark_period: labelMessages.benchmarkPeriod,
  goal_good: formMessages.goalMet,
  goal_normal: formMessages.goalSatisfactory
}

export const ORDER_COLUMN_TITLES = {
  location_name: labelMessages.location,
  arrive_at: labelMessages.delivery,
  item_name: labelMessages.item,
  coverage: labelMessages.orderCoverage,
  history_date: labelMessages.updatedAt,
  start_level: labelMessages.stockAtDelivery,
  total_need: labelMessages.neededAmount,
  effective_requested_amount: labelMessages.orderProposal,
  ordered_amount: labelMessages.orderedAmount,
  fulfilled_amount: labelMessages.fullfilledAmount,
  batch_size: labelMessages.customBatchSize,
  min_amount: labelMessages.minSize
}

export const FOODWASTE_COLUMN_TITLES = {
  location: labelMessages.location,
  date: labelMessages.date,
  category: labelMessages.wasteCategory,
  amount: labelMessages.measurement,
  open: labelMessages.status,
  num_sold: labelMessages.totalSoldNo,
  num_transactions: labelMessages.totalTransactionsNo,
  sales_cogs: labelMessages.totalCogs,
  grams_per_num_sold: labelMessages.amountPerSale,
  grams_per_num_transactions: labelMessages.amountPerTransaction,
  fraction_of_cogs: labelMessages.fractionOfCogs,
  waste: labelMessages.amountPerSale,
  sales_location: labelMessages.location,
  comment: labelMessages.comment,
  cogs_cost: labelMessages.salesCosts,
  disposal_cost: labelMessages.disposalCosts,
  water: labelMessages.water,
  co2: labelMessages.co2,
  kg_saved: labelMessages.kgSavedMetric,
  dishes_saved: labelMessages.dishesSavedMetric,
  kg_co2_saved: labelMessages.co2SavedMetric,
  cogs_saved: labelMessages.cogsSavedMetric,
  l_water_saved: labelMessages.waterSavedMetric
}

export const EVENTS_COLUMN_TITLES = {
  remote_pk: labelMessages.id,
  name: labelMessages.name,
  category: labelMessages.category,
  start_date: labelMessages.startDate,
  end_date: labelMessages.endDate,
  locations: labelMessages.locations,
  items: labelMessages.item,
  price_factor: labelMessages.priceReduction,
  batch_size: labelMessages.batchSize,
  references: labelMessages.referenceEvents
}

export const RECIPE_COLUMN_TITLES = {
  recipe_remote_pk: labelMessages.id,
  location: labelMessages.location,
  composite_name: labelMessages.composite,
  component_name: labelMessages.component,
  amount: labelMessages.amount
}

export const IMPORT_COLUMN_TITLES = {
  original_name: labelMessages.name,
  created_at: labelMessages.createdAt,
  format_name: labelMessages.format,
  sales_locations: labelMessages.location,
  status: labelMessages.status
}

export const CUSTOMERS_COLUMN_TITLES = {
  name: labelMessages.name,
  last_update: labelMessages.dataStatus,
  type: labelMessages.type,
  size: labelMessages.usersLimit,
  show_location_remote_pk: labelMessages.showLocationRemotePK,
  return_ratio_field: labelMessages.returnRatioField,
  food_waste_mode: labelMessages.foodWasteMode,
  dashboard_preview: labelMessages.dashboardPreview
}

export const TODO_ORDER_CONFIG_COLUMN_TITLES = {
  rank: labelMessages.rank,
  itemOrGroup: labelMessages.itemOrGroup,
  locationOrGroup: labelMessages.locationOrGroup,
  dateRange: labelMessages.dateRange,
  timeRange: labelMessages.timeRange,
  weekdays: labelMessages.weekdays,
  daily_quantile: labelMessages.dailyQuantile,
  scheduled_due_by: labelMessages.scheduledDueBy,
  scheduled_last_until: labelMessages.scheduledLastUntil,
  lead_quantile: labelMessages.leadQuantile,
  lead_interval: labelMessages.leadInterval,
  sustain_quantile: labelMessages.sustainQuantile,
  sustain_interval: labelMessages.sustainInterval,
  inventory_group_enabled: labelMessages.inventoryGroupEnabled,
  overplan_type: labelMessages.type,
  overplan_value: labelMessages.value,
  fulfillment_delay: labelMessages.fulfillmentDelay,
  order_factor: labelMessages.orderFactor,
  orders_enabled: labelMessages.order,
  reference_item: labelMessages.referenceItem,
  batch_rounding_cutoff: labelMessages.batchRoundingCutoff,
  multiday_tracking: labelMessages.multiDayTracking,
  inventory_tracking: labelMessages.inventoryTracking,
  min_amount_rounding_cutoff: labelMessages.minAmountRoundingCutoff
}

export const FORECAST_COLOR = 'rgb(0, 200, 83)'
export const FORECAST_DIFFERENCE_COLOR = 'rgba(0, 200, 83, 0.3)'
export const SOLD_COLOR = '#0050b3'
export const PLANNED_COLOR = '#eb2f96'
export const PRODUCED_COLOR = '#41d6f4'

// codes description http://www.worldweatheronline.com/feed/wwoConditionCodes.txt
export const WEATHER_CODES = [
  ['113'],
  ['116'],
  ['119'],
  ['122'],
  ['143', '248', '260'],
  ['176', '263', '266', '281', '293', '296', '353'],
  ['179', '323', '326', '368'],
  ['182', '311', '317', '362', '374'],
  ['185', '299', '302', '356'],
  ['200'],
  ['227'],
  ['230', '377', '386', '389'],
  ['284', '305', '308', '359'],
  ['314', '350', '365'],
  ['320'],
  ['329', '332', '371'],
  ['335', '338'],
  ['392', '395']
]

export const SUPER_SAFE_SETTING = 'super_safe'
export const SAFE_SETTING = 'safe'
export const NORMAL_SETTING = 'normal'
export const CLOSE_SETTING = 'close'

export const STUDENT_UNION = 'student_union'
export const BUSINESS = 'business'
export const BAKERY = 'bakery'

export const CUSTOMER_TYPES = {
  [STUDENT_UNION]: customersMessages.studentUnion,
  [BUSINESS]: customersMessages.business,
  [BAKERY]: customersMessages.bakery
}

export const OVERPLAN_TYPES = {
  QUANTILE: 'quantile',
  ABSOLUTE_VALUE: 'absolute_value',
  SALES_PERCENTAGE: 'sales_percentage',
  ORDER_PERCENTAGE: 'order_percentage'
}

export const OVERPLAN_TYPES_MESSAGES = {
  quantile: labelMessages.quantile,
  absolute_value: labelMessages.absoluteValue,
  sales_percentage: labelMessages.salesPercentage,
  order_percentage: labelMessages.orderPercentage
}

export const VERY_CONFIDENT = 'sehr sicher'
export const CONFIDENT = 'sicher'
export const NOT_CONFIDENT = 'unsicher'

export const ACTIVE_ICON_COLOR = '#1890ff'
export const DEFAULT_ICON_COLOR = '#aaa'

export const IMPORT_STATUS_TYPES = {
  FILE_READY: 'ready',
  FILE_IN_PROGRESS: 'in_progress',
  FILE_IMPORTED: 'imported',
  FILE_FAILED: 'failed',
  FILE_NO_COLUMN_MAPPING: 'no_column_mapping'
}

export const IMPORT_STATUS_MESSAGES = {
  [IMPORT_STATUS_TYPES.FILE_READY]: notifyMessages.readyToImport,
  [IMPORT_STATUS_TYPES.FILE_NO_COLUMN_MAPPING]: notifyMessages.readyToImport,
  [IMPORT_STATUS_TYPES.FILE_IN_PROGRESS]: notifyMessages.importing,
  [IMPORT_STATUS_TYPES.FILE_IMPORTED]: notifyMessages.imported,
  [IMPORT_STATUS_TYPES.FILE_FAILED]: notifyMessages.failed
}

export const ACTIONS_HEIGHT = 140
export const ADMIN_ACTIONS_HEIGHT = 223
export const TABLE_HEADER_HEIGHT = 37
export const TABLE_ROW_HEIGHT = 37
export const TABLE_FOOTER_HEIGHT = 37
export const DEFAULT_TABLE_HEIGHT = 740
export const BOTTOM_MENU_HEIGHT = 48
export const MOBILE_BREAKPOINT = 769
export const TABLET_BREAKPOINT = 1025

export const IMPORT_ERRORS = {
  exported_file_start_end_date: notifyMessages.wrongFileDates,
  wrong_POS_location: notifyMessages.wrongPosLocation,
  file_not_supported: notifyMessages.fileNotSupported,
  wrong_file_type_csv: notifyMessages.wrongCsvFile,
  wrong_file_type_excel: notifyMessages.wrongExcelFile,
  cannot_read_file: notifyMessages.cannotReadFile,
  no_menuline_mapping: notifyMessages.noMenulineMapping
}

export const EVENT_TYPES = {
  PROMOTION: 'promotion',
  OTHER: 'other',
  SEMESTER_BREAK: 'semester_break'
}

export const WEB_SOCKET_OPTIONS = {
  share: true,
  retryOnError: true
}

export const OTHER_GROUP_THRESHOLD = 0.02

export const DEFAULT_WHERE = [
  { field: 'meco_item__forecasts', operator: '=', value: true }
]
export const DEFAULT_WHERE_HOURLY = [
  { field: 'item_offering__meco_item__forecasts', operator: '=', value: true }
]
export const WHERE_NUM_SOLD_NOT_ZERO = [
  { field: 'num_sold', operator: '!in', value: [0, null] }
]
export const WHERE_NUM_SOLD_NOT_ZERO_HOURLY = [
  { field: 'amount', operator: '!=', value: 0 }
]

export const CARD_PROPS = [
  'className',
  'extra',
  'onMouseDown',
  'onMouseUp',
  'onTouchEnd',
  'style'
]

export const CUSTOM_RANGE = 'customRange'
export const FLOATING_RANGE = 'floatingRange'

export const BASE_VALUE_FORMAT = {
  decimalSeparator: ',',
  decimalScale: 0,
  fixedDecimalScale: false,
  displayType: 'text',
  defaultValue: '-',
  thousandSeparator: '.'
}

export const CURRENCY_VALUE_FORMAT = {
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  displayType: 'text',
  suffix: ' €',
  defaultValue: '-',
  thousandSeparator: '.'
}

export const WATER_VALUE_FORMAT = {
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  displayType: 'text',
  defaultValue: '-',
  thousandSeparator: '.'
}

export const CO2_VALUE_FORMAT = {
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  displayType: 'text',
  defaultValue: '-',
  thousandSeparator: '.'
}

export const WEIGHT_VALUE_FORMAT = {
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  displayType: 'text',
  suffix: ' kg',
  thousandSeparator: '.'
}

export const LIQUID_VALUE_FORMAT = {
  decimalSeparator: ',',
  decimalScale: 2,
  displayType: 'text',
  suffix: ' l',
  thousandSeparator: '.'
}

export const PERCENT_VALUE_FORMAT = {
  decimalSeparator: ',',
  decimalScale: 2,
  displayType: 'text',
  suffix: ' %',
  thousandSeparator: '.'
}

export const SMALL_WEIGHT_VALUE_FORMAT = {
  decimalSeparator: ',',
  displayType: 'text',
  suffix: ' g',
  defaultValue: '-',
  thousandSeparator: '.'
}

export const FOODWASTE_SUBSCRIPTION_TIME = '05:00'

export const SCALE = {
  sm: 'sm',
  md: 'md',
  lg: 'lg'
}

export const BADGE_COLORS =
  [
    '#800000',
    '#e50000',
    '#FF4081',
    '#FF7FAB',
    '#f900ea',
    '#EA80FC',
    '#bf55ec',
    '#9b59b6',
    '#7C4DFF',
    '#0231E8',
    '#81B1FF',
    '#3397dd',
    '#4094DA',
    '#04A9F4',
    '#02BCD4',
    '#1bbc9c',
    '#39ad77',
    '#2ecd6f',
    '#f9d900',
    '#AF7E2E',
    '#ff7800',
    '#E65100',
    '#b5bcc2',
    '#667684'
  ]

export const ACCENT_COLORS = {
  NEUTRAL: {
    100: '#121619',
    70: '#4d5358',
    50: '#878d96',
    40: '#a2a9b0',
    30: '#c1c7cd',
    20: '#dde1e6',
    10: '#f3f5f7',
    5: '#fafbfc'
  },
  PEACHROSE: {
    100: '#8b2333',
    75: '#b14c5c',
    50: '#da7a89',
    25: '#f1b0ba',
    10: '#f9d1d7',
    5: '#fce9ec'
  },
  LAVENDER: {
    100: '#9a109d',
    75: '#bf41c1',
    50: '#d977db',
    25: '#ecbeed',
    10: '#f6d6f6',
    5: '#fbe9fc'
  },
  LILAC: {
    100: '#673188',
    75: '#8d4eb5',
    50: '#af6bd9',
    25: '#e7c8ff',
    10: '#f2e2ff',
    5: '#f8f0ff'
  },
  CORNFLOWER: {
    100: '#433c94',
    75: '#4c41c1',
    50: '#7f77db',
    25: '#ccc8ff',
    10: '#e4e2ff',
    5: '#f1f0ff'
  },
  HYDRANGEA: {
    100: '#327093',
    75: '#4e8fb5',
    50: '#6bb1d9',
    25: '#c8ebff',
    10: '#e2f4ff',
    5: '#f0faff'
  },
  SPRINGSTAR: {
    100: '#199891',
    75: '#5ab4af',
    50: '#90d2cf',
    25: '#beeeec',
    10: '#dffdfc',
    5: '#edfffe'
  },
  GRAS: {
    100: '#32835a',
    75: '#32aa6e',
    50: '#70c69b',
    25: '#a4dfc1',
    10: '#c7f2dd',
    5: '#e2f4eb'
  },
  NARCISSUS: {
    100: '#f2b600',
    75: '#f8c323',
    50: '#fbd561',
    25: '#ffe289',
    10: '#fff2ca',
    5: '#fdf7e5'
  },
  MARIGOLD: {
    100: '#df6426',
    75: '#eb7338',
    50: '#ee8d5c',
    25: '#ffb089',
    10: '#ffdcca',
    5: '#fdede5'
  },
  GERBERA: {
    100: '#b0391b',
    75: '#cf583a',
    50: '#e27c62',
    25: '#f5a793',
    10: '#f9d3ca',
    5: '#fcede9'
  },
  ROSE: {
    100: '#8e2e2b',
    75: '#d14a45',
    50: '#e26662',
    25: '#f59693',
    10: '#f9cccA',
    5: '#fceae9'
  }
}

const levels = [100, 75, 50]
const backgrounds = mapValues(ACCENT_COLORS, o => [(o[25] || o[20]), o[10], o[5]])
const fontColors = mapValues(ACCENT_COLORS, o => [o[100], (o[75] || o[70]), o[50]])

export const ACCENT_COLORS_LIST = []
forEach(backgrounds, (value, key) => {
  ACCENT_COLORS_LIST.push(...(value.map((o, idx) => ({
    id: `${key}-${levels[idx]}`,
    bg: o,
    fg: fontColors[key][idx]
  }))))
})

export const TEMPLATE_STEPS = {
  FIRST: 'first',
  FILTERS: 'filters',
  TEMPLATES: 'templates'
}

export const Filter = {
  LOCATIONS: 'locations',
  LOCATION: 'location',
  DATE_RANGE: 'dateRange',
  COMPARISON: 'comparison',
  WEEKDAY: 'weekday',
  ITEM: 'item',
  ITEM_TAG: 'itemTag',
  ITEM_GROUP_1: 'itemGroup1',
  ITEM_GROUP_2: 'itemGroup2',
  OFFERING_GROUP_1: 'offeringGroup1',
  OFFERING_GROUP_2: 'offeringGroup2',
  OFFERING_GROUP_1_NAMES: 'offeringGroup1Names',
  OFFERING_GROUP_2_NAMES: 'offeringGroup2Names',
  ORDER_ACTIVE: 'orderActive',
  FOODWASTE_CATEGORY: 'foodwasteCategory',
  STATUS: 'status',
  AS_OF: 'as_of'
}

export const TODO_CONFIG_TABLES = [
  'totalQuantile',
  'initialTodos',
  'scheduling',
  'inventoryGroup'
]

export const ORDER_CONFIG_TABLES = [
  'ordersEnabled',
  'quantile',
  'fulfillmentDelay',
  'orderFactor',
  'referenceItem',
  'batchRounding',
  'multidayTracking'
]

export const DATA_FRESHNESS_TYPE = {
  CUSTOMER_UPDATE: 'customer_update',
  INVENTORY_ADJUSTMENT: 'inventory_adjustment',
  ITEM_UPDATE: 'item_update',
  ITEM_OFFERING: 'item_offering',
  FULFILLED_ORDER: 'fulfilled_order',
  SALE: 'sale',
  SALES_LOCATION_UPDATE: 'sales_location_update',
  TODO_UPDATE: 'todo_update',
  WASTE_ENTRY: 'waste_entry'
}
