import { OPERATOR } from 'constants/index'
import { qFetchCustomers, qFetchDataFreshness, qFetchLocations, qFetchUser } from './queries'
import { useGeneralStore, useInitializationStore } from 'hooks/store'
import db64 from 'db64'
import { differenceInHours } from 'date-fns'
import { getRequestToken } from 'services/createFetch'
import { useDataStore } from 'hooks/store/useDataStore'

const CACHE_STALE_HOURS = 168 // within 7 days we take data from cache
export const init = async (queryClient, fetch, cookies, logger, userObj) => {
  const now = new Date()

  const requestToken = getRequestToken(null, cookies)
  useGeneralStore.setState({ token: requestToken })

  await db64.create('cache', ['customers', 'locations'])
  const customersStore = await db64.use('cache', 'customers')
  const locationsStore = await db64.use('cache', 'locations')
  useGeneralStore.setState({ customersStore, locationsStore })

  if (!requestToken) return false

  const setAllCustomers = useGeneralStore.getState().setAllCustomers

  let user = userObj || queryClient.getQueryData(['me', requestToken])
  if (!user) {
    user = await qFetchUser(fetch)
    queryClient.setQueryData(['me', requestToken], user)
    useInitializationStore.getState().addLoaded('user', null, logger)
  }

  if (user.role === OPERATOR) {
    useInitializationStore.getState().addInitialization('customers', logger)

    const cachedCustomers = await customersStore.getEntries(['data', 'timestamp'])
    const shouldUseCachedCustomers = false // cachedCustomers?.data ? differenceInHours(new Date(), new Date(cachedCustomers?.timestamp)) < CACHE_STALE_HOURS : false
    let customers
    if (shouldUseCachedCustomers) {
      logger(`Loading Screen: Using cached customers. Last updated ${differenceInHours(new Date(), new Date(cachedCustomers.timestamp))} hours ago.`)
      customers = cachedCustomers.data
    } else {
      customers = await qFetchCustomers(fetch)
      customersStore.setEntries({ data: customers, timestamp: Date.now() })
    }
    queryClient.setQueryData(['customers'], customers)
    useInitializationStore.getState().addLoaded('customers', null, logger)
    setAllCustomers(customers)
  }

  const setCustomer = useGeneralStore.getState().setCustomer
  const customer = user.role === OPERATOR && window.location.hash && window.location.hash.includes('c_')
    ? parseInt(window.location.hash.split('c_')[1])
    : user.customer_id
  setCustomer(customer, user)

  const dataFreshness = await qFetchDataFreshness(fetch, customer)
  if (dataFreshness) {
    useDataStore.getState().setDataFreshness(dataFreshness)
  }

  const cachedLocations = await locationsStore.getEntries([`${customer}_data`, `${customer}_timestamp`])
  useGeneralStore.setState({ cachedLocations })

  const shouldUseCachedLocations = cachedLocations[`${customer}_data`] ? differenceInHours(new Date(), new Date(cachedLocations[`${customer}_timestamp`])) < CACHE_STALE_HOURS : false
  let locations
  const locationFilter = {
    customer,
    forecast_enabled: true,
    page_size: 9999,
    ordering: 'name'
  }
  if (shouldUseCachedLocations) {
    logger(`Loading Screen: Using cached locations. Last updated ${differenceInHours(new Date(), new Date(cachedLocations[`${customer}_timestamp`]))} hours ago.`)
    locations = cachedLocations[`${customer}_data`]

    // We still load the locations in the background
    qFetchLocations(fetch, locationFilter, false).then((res) => {
      locationsStore.setEntries({ [`${customer}_data`]: res, [`${customer}_timestamp`]: Date.now() })
      queryClient.setQueryData(['sales-locations', locationFilter], res)
    })
  } else {
    locations = await qFetchLocations(fetch, locationFilter, false)
    locationsStore.setEntries({ [`${customer}_data`]: locations, [`${customer}_timestamp`]: Date.now() })
  }

  queryClient.setQueryData(['sales-locations', locationFilter], locations)
  useInitializationStore.getState().addLoaded('locations', null, logger)

  const loadingDuration = Date.now() - now
  logger(`Loading Screen: All initializations finished in ${loadingDuration}ms.`)

  useInitializationStore.getState().setLoadingDuration(loadingDuration)

  return true
}
