import globalMessages from 'components/globalMessages'
import s from './GridComponents.module.scss'
import { useIntl } from 'react-intl'
import { ToggleGroup } from 'components/Primitives/ToggleGroup'
import { clamp, uniq } from 'lodash'
import classNames from 'classnames'
import messages from './messages'
import { Text } from 'components/Primitives'
import { Button } from 'components/Button'
import { baseNumberFormatter } from './formatter'
import { useEffect } from 'react'

const PageButton = ({ page, currentPage, onClick }) => (
  <button data-action={`page-${page}`} type='button' data-state={page === currentPage ? 'active' : 'inactive'} className={classNames(s.pageButton, { [s.active]: page === currentPage })} onClick={onClick}>{page}</button>
)

const renderPageButtons = (page, pages, setPage) => {
  const pageButtons = []
  if (pages <= 6) {
    for (let p = 1; p <= pages; p += 1) {
      pageButtons.push(
        <PageButton
          key={`c_page${p}`}
          page={p}
          currentPage={page}
          onClick={() => setPage(p)}
        />
      )
    }
  } else {
    const toShow = uniq(
      [page === pages ? page - 2 : null, page - 1, page, page + 1, page === 1 ? page + 2 : null].filter(
        (i) => i > 0 && i <= pages
      )
    )
    toShow.forEach((p) => {
      pageButtons.push(
        <PageButton
          key={`c_page${p}`}
          page={p}
          currentPage={page}
          onClick={() => setPage(p)}
        />
      )
    })
  }
  return pageButtons
}

export const Pagination = ({ rowCount, page, pageSize, onPageSizeChange, onPageChange, allowedPageSizes, className }) => {
  const intl = useIntl()
  const hasData = rowCount != null

  const totalPages = hasData ? clamp(Math.ceil(rowCount / pageSize), 1, Infinity) : '?'

  useEffect(() => {
    if (page > totalPages) {
      onPageChange(totalPages)
    }
  }, [page, totalPages])

  const total = hasData ? baseNumberFormatter({ value: rowCount }) : '?'
  const range0 = baseNumberFormatter({ value: ((page - 1) * pageSize) + 1 })
  const range1 = baseNumberFormatter({ value: clamp(page * pageSize, 0, hasData ? rowCount : 100) })

  return (
    <div role='pagination' className={classNames(s.pagination, className)}>
      <div className='flex desktop:w-1/3'>
        <div className='mr-6 mobile:mr-2'>
          <Text color='gray' className='mobile:hidden' type='table' size='m'>{intl.formatMessage(messages.rowsPerPage)}</Text>
          <ToggleGroup value={pageSize} onChange={onPageSizeChange} className='ml-2' size='s'>
            {allowedPageSizes.map(size => ({ value: size, label: size }))}
          </ToggleGroup>
        </div>
        <div data-role='pagination-items' className='whitespace-nowrap'>
          <Text color='gray' type='table' size='m'>
            <span className={classNames(s.detailedRange)}>
              {rowCount
                ? intl.formatMessage(globalMessages.tableItems, {
                    total,
                    range0,
                    range1
                  })
                : intl.formatMessage(globalMessages.noData)}
            </span>
          </Text>
        </div>
      </div>

      <div className={s.pageButtons}>
        <Button data-action='first-page' pure size='s' icon='ChevronLeftDouble' type='neutral' onClick={() => onPageChange(1)} />
        <Button data-action='previous-page' pure size='s' icon='ChevronLeft' type='neutral' onClick={() => onPageChange(clamp(page - 1, 1, totalPages))} />
        <div data-role='page-buttons' className={s.pageIndicator}>
          {renderPageButtons(page, totalPages, onPageChange)}
        </div>
        <Button data-action='next-page' pure size='s' icon='ChevronRight' type='neutral' onClick={() => onPageChange(clamp(page + 1, 1, totalPages))} />
        <Button data-action='last-page' pure size='s' icon='ChevronRightDouble' type='neutral' onClick={() => onPageChange(totalPages)} />
      </div>
      <div className='w-1/3 mobile:hidden tablet:hidden' dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
    </div>
  )
}
