import classNames from 'classnames'
import { Calendar, RangeCalendar } from 'components/Primitives'
import { RangePreview } from 'components/RangePreview'
import globalMessages from 'components/globalMessages'
import labelMessages from 'components/labelMessages'
import { addDays, isAfter, isSameDay } from 'date-fns'
import { isArray } from 'lodash'
import { useMemo } from 'react'
import { calculateFloatingRange, rangeToFloating } from 'utils/datetime'

export const FloatingPeriodPicker = ({ value, className, onChange, maxDate, minDate, fixedRangeLength, invalidRangeMessage, reference }) => {
  const calculatedRange = useMemo(
    () => calculateFloatingRange(value, false, !!fixedRangeLength, reference),
    [value, fixedRangeLength, reference]
  )

  const isValid = useMemo(() => {
    return calculatedRange &&
      (fixedRangeLength ||
        (isAfter(calculatedRange[1], calculatedRange[0]) ||
          isSameDay(calculatedRange[1], calculatedRange[0]))) &&
      (!minDate ||
        ((isAfter(calculatedRange[0], minDate) || isSameDay(calculatedRange[0], minDate)) &&
          (fixedRangeLength || isAfter(calculatedRange[1], minDate) || isSameDay(calculatedRange[1], minDate)))) &&
      (!maxDate ||
        ((fixedRangeLength || isAfter(maxDate, calculatedRange[1]) || isSameDay(maxDate, calculatedRange[1])) &&
          (isAfter(maxDate, calculatedRange[0]) || isSameDay(maxDate, calculatedRange[0]))))
  }, [calculatedRange, fixedRangeLength, minDate, maxDate])

  const handleSelectionChange = (range) => {
    if (isValid) onChange(rangeToFloating(range, reference))
  }

  return (
    <div className={classNames('flex flex-col space-y-4', className)}>
      {fixedRangeLength
        ? <Calendar onChange={handleSelectionChange} value={calculatedRange?.length ? calculatedRange[0] : null} maxValue={maxDate} />
        : <RangeCalendar onChange={handleSelectionChange} value={calculatedRange?.length ? calculatedRange : null} noPreview />}

      <RangePreview range={fixedRangeLength && calculatedRange ? [calculatedRange[0], addDays(calculatedRange[0], fixedRangeLength - 1)] : calculatedRange} isValid={isValid} message={invalidRangeMessage} />
    </div>
  )
}

export const formatFloatingPeriod = (value, intl) => {
  if (isArray(value)) return 'R: ' + value.join(' | ')
  const pastUnits = ['weeksPast', 'daysPast']
  const fromIsWeek = value.from.unit.includes('weeks')
  const toIsWeek = value.to.unit.includes('weeks')
  const fromMessage = pastUnits.includes(value.from.unit) ? labelMessages.unitAgo : labelMessages.unitFromNow
  const fromUnit = intl.formatMessage(fromIsWeek ? labelMessages.weeksDative : labelMessages.daysDative, { count: value.from.value })
  const toMessage = pastUnits.includes(value.to.unit) ? labelMessages.unitAgo : labelMessages.unitFromNow
  const toUnit = intl.formatMessage(toIsWeek ? labelMessages.weeksDative : labelMessages.daysDative, { count: value.to.value })

  const fromString = value.from.value === 0 ? intl.formatMessage(globalMessages.today) : intl.formatMessage(fromMessage, { unit: fromUnit, count: value.from.value })
  const toString = value.to.value === 0 ? intl.formatMessage(globalMessages.today) : intl.formatMessage(toMessage, { unit: toUnit, count: value.to.value })

  if (fromString === toString) {
    return fromString
  }
  return `${fromString} → ${toString}`
}
