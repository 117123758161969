import {
  BASE_VALUE_FORMAT,
  WEEKDAY_DATE_FORMAT
} from 'constants/index'
import { isSameDay } from 'date-fns'
import messages from 'components/Charts/messages'
import { formatLocalized, parseDate } from 'utils/datetime'
import { FormattedMessage, useIntl } from 'react-intl'
import { FlexibleScaledNumberFormat } from 'components/FlexibleScaledNumberFormat'
import labelMessages from 'components/labelMessages'
import { getRangeFilter } from '../utils'

export const DeltaTooltip = ({ prevRangeParams, prevValue, difference, format, isFormatted, v2, targetComparison }) => {
  const intl = useIntl()
  const dateParam = getRangeFilter(prevRangeParams)
  if (!dateParam) {
    return null
  }

  let toolTip
  const formatDay = (date) => formatLocalized(date, WEEKDAY_DATE_FORMAT, intl)

  const prevValueText = v2
    ? (
      <p data-cy='prev-value-label'>
        <FormattedMessage {...targetComparison ? labelMessages.target : labelMessages.value} />:{' '}
        {isFormatted
          ? prevValue
          : Array.isArray(prevValue)
            ? (
              <>
                <FlexibleScaledNumberFormat
                  value={prevValue[0]}
                  {... (format != null ? format : BASE_VALUE_FORMAT)}
                />
                <span className='mx-1'>-</span>
                <FlexibleScaledNumberFormat
                  value={prevValue[1]}
                  {... (format != null ? format : BASE_VALUE_FORMAT)}
                />
              </>
              )
            : <FlexibleScaledNumberFormat
                value={prevValue}
                {... (format != null ? format : BASE_VALUE_FORMAT)}
              />}
      </p>
      )
    : null
  const differenceText = v2
    ? (
      <p data-cy='diff-value-label'>
        <FormattedMessage {...labelMessages.difference} />:{' '}
        {isFormatted
          ? difference
          : <FlexibleScaledNumberFormat
              value={difference}
              {... (format != null ? format : BASE_VALUE_FORMAT)}
            />}
      </p>
      )
    : null

  if (dateParam.operator === 'in') {
    if (dateParam.value.length === 1) {
      toolTip = (
        <>
          {!targetComparison && (
            <p className={v2 ? 'mb-2' : null} data-cy='tooltip-comparison-label'>
              {intl.formatMessage(messages.comparisonPeriodTitle)}
              <br />
              {formatDay(parseDate(dateParam.value[0]))}
            </p>
          )}
          {prevValueText}
          {differenceText}
        </>
      )
    } else {
      // if we have specific days instead of a range, we can't show useful data here
      toolTip = (
        <>
          {!targetComparison && (
            <p className={v2 ? 'mb-2' : null} data-cy='tooltip-comparison-label'>
              {intl.formatMessage(messages.weekdayComparisonText)}
            </p>
          )}
          {prevValueText}
          {differenceText}
        </>
      )
    }
  } else {
    const from = parseDate(dateParam.value[0])
    const to = parseDate(dateParam.value[1])

    toolTip = isSameDay(from, to)
      ? (
        <>
          {!targetComparison && (
            <p className={v2 ? 'mb-2' : null} data-cy='tooltip-comparison-label'>
              {intl.formatMessage(messages.comparisonPeriodTitle)}
              <br />
              {formatDay(from)}
            </p>
          )}
          {prevValueText}
          {differenceText}
        </>
        )
      : (
        <>
          {!targetComparison && (
            <p className={v2 ? 'mb-2' : null} data-cy='tooltip-comparison-label'>
              {intl.formatMessage(messages.comparisonPeriodTitle)}
              <br />
              {intl.formatMessage(messages.comparisonPeriod, {
                from: formatDay(from),
                to: formatDay(to)
              })}
            </p>
          )}
          {prevValueText}
          {differenceText}
        </>
        )
  }

  return <div>{toolTip}</div>
}
