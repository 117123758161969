export const PulseLogo = () => {
  return (
    <svg
      className='w-28 h-28 animate-pulsate-fwd' width='35' height='47' viewBox='0 0 35 47' fill='none' xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='35' height='47' fill='white' />
      <path
        fillRule='evenodd' clipRule='evenodd'
        d='M33.1596 18.9908C31.9278 16.8509 29.7258 15.574 27.2591 15.574H6.82432C4.36084 15.574 2.1539 16.8509 0.923806 18.9908C-0.307935 21.1322 -0.307935 23.6862 0.923806 25.8244L11.1428 43.5832C12.3729 45.723 14.5815 47 17.0433 47C19.5052 47 21.7121 45.723 22.9439 43.5832L33.1612 25.8244C34.3913 23.6845 34.3913 21.1306 33.1596 18.9908ZM6.82111 25.8244C5.56799 25.8244 4.55003 24.2983 4.55003 22.4076C4.55003 20.5219 5.56634 18.9908 6.82111 18.9908C8.07423 18.9908 9.09218 20.5219 9.09218 22.4076C9.09218 24.2967 8.07587 25.8244 6.82111 25.8244ZM9.66124 31.2869C9.66124 33.1743 10.6792 34.7037 11.9323 34.7037C13.1854 34.7037 14.2034 33.176 14.2034 31.2869C14.2034 29.3995 13.1854 27.8701 11.9323 27.8701C10.6775 27.8701 9.66124 29.3995 9.66124 31.2869ZM17.0401 43.5832C15.7869 43.5832 14.769 42.0538 14.769 40.1664C14.769 38.279 15.7853 36.7496 17.0401 36.7496C18.2932 36.7496 19.3111 38.279 19.3111 40.1664C19.3111 42.0538 18.2948 43.5832 17.0401 43.5832ZM14.769 22.4076C14.769 24.2983 15.7869 25.8244 17.0401 25.8244C18.2948 25.8244 19.3111 24.2967 19.3111 22.4076C19.3111 20.5219 18.2932 18.9908 17.0401 18.9908C15.7853 18.9908 14.769 20.5219 14.769 22.4076ZM22.1512 34.7038C20.8981 34.7038 19.8801 33.1744 19.8801 31.287C19.8801 29.3995 20.8965 27.8685 22.1512 27.8685C23.4043 27.8685 24.4223 29.3995 24.4223 31.287C24.4223 33.176 23.4043 34.7038 22.1512 34.7038ZM24.9879 22.4076C24.9879 24.2983 26.0059 25.8244 27.259 25.8244C28.5138 25.8244 29.5301 24.2967 29.5301 22.4076C29.5301 20.5219 28.5121 18.9908 27.259 18.9908C26.0042 18.9908 24.9879 20.5219 24.9879 22.4076Z'
        fill='url(#paint0_linear)'
      />
      <path
        d='M26.2041 9.10325C24.3139 10.8921 21.8539 12.0147 19.1997 12.4098C18.4703 12.518 17.7326 12.5744 16.9933 12.576C15.7008 12.5791 14.4 12.4302 13.1766 12.0366C10.2227 11.0897 7.99657 8.59214 7.38405 5.69325C7.23421 4.98617 7.17988 4.26183 7.22104 3.54377L7.22927 3.40424L7.37582 3.3964C9.9148 3.26313 12.4027 4.16777 14.1991 5.87825C15.546 7.16073 16.4137 8.81007 16.7068 10.5723C16.9834 7.8929 18.2266 5.36087 20.2502 3.43403C22.7233 1.07917 26.1448 -0.16568 29.6355 0.0177541L29.7837 0.0255932L29.7903 0.166697C29.927 3.10165 28.967 6.00054 26.9978 8.27388C26.7458 8.56549 26.4807 8.84143 26.2041 9.10325Z'
        fill='url(#paint1_linear)'
      />
      <defs>
        <linearGradient
          id='paint0_linear' x1='14.5537' y1='47.2099' x2='40.3923' y2='29.6055'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF3CB4' />
          <stop offset='1' stopColor='#FF643C' />
        </linearGradient>
        <linearGradient
          id='paint1_linear' x1='19.4376' y1='15.8519' x2='28.8547' y2='-0.860128'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#007D96' />
          <stop offset='1' stopColor='#00FA7D' />
        </linearGradient>
      </defs>
    </svg>
  )
}
