import { defineMessages } from 'react-intl'

export default defineMessages({
  locationTitleSingle: {
    id: 'pickers.locationTitleSingle',
    defaultMessage: 'Select a location'
  },
  locationTitleMulti: {
    id: 'pickers.locationTitleMulti',
    defaultMessage: 'Select one or more locations'
  },
  booleanPicker: {
    id: 'pickers.booleanPicker',
    defaultMessage: 'Select an option'
  },
  accessRightsTitleSingle: {
    id: 'pickers.accessRightsTitleSingle',
    defaultMessage: 'Select an access right'
  },
  accessRightsTitleMulti: {
    id: 'pickers.accessRightsTitleMulti',
    defaultMessage: 'Select one or more access rights'
  },
  addAdditionalCategory: {
    id: 'pickers.addAdditionalCategory',
    defaultMessage: 'Add'
  },
  addRow: {
    id: 'pickers.addRow',
    defaultMessage: 'Add monitoring for additional location'
  },
  matchBy: {
    id: 'pickers.matchBy',
    defaultMessage: 'Match by'
  },
  matchingWeekdays: {
    id: 'label.matchingWeekdays',
    defaultMessage: 'Matching Weekdays'
  },
  isoWeek: {
    id: 'label.isoWeek',
    defaultMessage: 'Iso week number'
  },
  month: {
    id: 'label.month',
    defaultMessage: 'Month'
  },
  quarter: {
    id: 'label.quarter',
    defaultMessage: 'Quarter'
  },
  year: {
    id: 'label.year',
    defaultMessage: 'Year'
  },
  floatingStartDate: {
    id: 'label.floatingStartDate',
    defaultMessage: 'Floating Start Date'
  },
  specificStartDate: {
    id: 'label.specificStartDate',
    defaultMessage: 'Specific Start Date'
  },
  previousWeek: {
    id: 'label.previousWeek',
    defaultMessage: 'Previous week'
  },
  previousMonth: {
    id: 'label.previousMonth',
    defaultMessage: 'Previous month'
  },
  previousQuarter: {
    id: 'label.previousQuarter',
    defaultMessage: 'Previous quarter'
  },
  previousYear: {
    id: 'label.previousYear',
    defaultMessage: 'Previous year'
  },
  previousNDays: {
    id: 'label.previousNDays',
    defaultMessage: '{days, plural, one {Previous day} other {Previous {days} days}}'
  },
  yearBeforeLast: {
    id: 'label.yearBeforeLast',
    defaultMessage: 'Year before last'
  },
  customDateRange: {
    id: 'label.customDateRange',
    defaultMessage: 'Custom date range'
  },
  noComparison: {
    id: 'label.noComparison',
    defaultMessage: 'No comparison'
  },
  dateRangeFilter: {
    id: 'label.dateRangeFilter',
    defaultMessage: 'Date Range'
  },
  rollingDateRange: {
    id: 'label.rollingDateRange',
    defaultMessage: 'Rolling date range'
  },
  rollingDateRangeTooltip: {
    id: 'label.rollingDateRangeTooltip',
    defaultMessage: 'Automatically update the date range to move forward over time'
  },
  customStartDate: {
    id: 'label.customStartDate',
    defaultMessage: 'Custom start date'
  },
  rollingStartDate: {
    id: 'label.rollingStartDate',
    defaultMessage: 'Rolling start date'
  },
  rollingStartDateTooltip: {
    id: 'label.rollingStartTooltip',
    defaultMessage: 'Automatically update the start date if the date range filter changes'
  }
})
