import { DATA_FRESHNESS_TYPE } from 'constants/index'
import { isToday, parseISO } from 'date-fns'
import { create } from 'zustand'

export const useDataStore = create((set) => ({
  dataFreshness: null,
  hasTodayData: {
    [DATA_FRESHNESS_TYPE.CUSTOMER_UPDATE]: null,
    [DATA_FRESHNESS_TYPE.FULFILLED_ORDER]: null,
    [DATA_FRESHNESS_TYPE.INVENTORY_ADJUSTMENT]: null,
    [DATA_FRESHNESS_TYPE.ITEM_OFFERING]: null,
    [DATA_FRESHNESS_TYPE.ITEM_UPDATE]: null,
    [DATA_FRESHNESS_TYPE.SALE]: null,
    [DATA_FRESHNESS_TYPE.SALES_LOCATION_UPDATE]: null,
    [DATA_FRESHNESS_TYPE.TODO_UPDATE]: null,
    [DATA_FRESHNESS_TYPE.WASTE_ENTRY]: null
  },
  setDataFreshness: (dataFreshness) => set((state) => {
    if (!dataFreshness) {
      return state
    }
    const hasTodayData = {}
    Object.keys(DATA_FRESHNESS_TYPE).forEach((key) => {
      const dataKey = `last_${DATA_FRESHNESS_TYPE[key]}`
      hasTodayData[DATA_FRESHNESS_TYPE[key]] = dataFreshness[dataKey] == null
        ? false
        : isToday(parseISO(dataFreshness[dataKey]))
    })

    if (dataFreshness) {
      return { dataFreshness, hasTodayData }
    }
    return state
  })
}))
